import React, { useEffect } from "react";
import "./style.css";

import LocationSVG from "../../assets/location";
import Phonesvg from "../../assets/phone";
import Mailsvg from "../../assets/mail";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import FadeInSection from '../../utils/fade_in_section'

const ContactPage = () => {
  const openLink = (url) =>  {
    window.open(url, "_blank");
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  };

  return (
    <>
      <Header/>
      {/* container containing all the aboslute divs */}
      {/* <div className="absolute absolute-container"> */}
        {/* background gradient circles */}
        {/* <div className="absolute -left-32 -top-48 w-[691.78px] h-[660.39px] bg-gfg-green origin-top-left rounded-full blur-[650px] -z-10" /> */}
        {/* end of background gradient circles */}
      {/* </div> */}

      <FadeInSection>
        <div className="container w-full align-center items-center flex flex-col">
          <h1 className="font-extrabold xs:w-7/8 lg:w-5/6 xs:mt-4 lg:mt-24 text-center xs:text-3xl lg:text-5xl"> Want to know more about GeeksforGeeks PCCOE Student Chapter?</h1>
          <h1 className="text-center mt-4 xs:w-7/8 lg:w-5/6 xs:text-xl lg:text-2xl"> Follow us at our social media handles to stay up to date!</h1>
        </div>

        {/* social media handles and map */}
        <div className="flex h-7/8 mx-auto justify-evenly mx-auto xs:mt-12 lg:mt-24 xs:flex-col lg:flex-row xs:items-center">
          <div className="links flex flex-col gap-y-8 xs:items-center lg:items-start">
            {/* Linkedin */}
            <div className="flex">
              <button
                type="button"
                data-te-ripple-init
                data-te-ripple-color="light"
                className="mb-2 inline-block rounded items-center px-6 py-2.5 text-xs flex flex-row gap-x-10 font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg bg-[#0077b5] rounded-md"
                onClick={ () => window.open("https://www.linkedin.com/company/gfgpccoe/") }>
                <h1 className="py-2 text-lg font-bold">LinkedIn</h1>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="currentColor"
                  viewBox="0 0 24 24">
                  <path
                    d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
                </svg>
              </button>
            </div>

            {/* Instagram */}
            <div className="flex flex-row">
              <button
                type="button"
                data-te-ripple-init
                data-te-ripple-color="light"
                className="mb-2 inline-block rounded items-center px-6 py-2.5 text-xs flex flex-row gap-x-10 font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg bg-[#c13584] rounded-md"
                onClick={ () => window.open("https://www.instagram.com/gfg_pccoe/") }>
                <h1 className="py-2 text-lg font-bold">Instagram</h1>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="currentColor"
                  viewBox="0 0 24 24">
                  <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                </svg>
              </button>
            </div>

            {/* Twitter */}
            <div className="flex flex-row">
              <button
                type="button"
                data-te-ripple-init
                data-te-ripple-color="light"
                className="mb-2 inline-block rounded items-center px-6 py-2.5 text-xs flex flex-row gap-x-10 font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg bg-[#1da1f2] rounded-md"
                onClick={ () => window.open("https://twitter.com/gfg_pccoe") }>
                <h1 className="py-2 text-lg font-bold">Twitter</h1>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="currentColor"
                  viewBox="0 0 24 24">
                   <path
                    d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
                </svg>
              </button>
            </div>

            {/* Youtube */}
            <div className="flex flex-row">
              <button
                type="button"
                data-te-ripple-init
                data-te-ripple-color="light"
                className="mb-2 inline-block rounded items-center px-6 py-2.5 text-xs flex flex-row gap-x-10 font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg bg-[#ff0000] rounded-md"
                onClick={ () => window.open("https://www.youtube.com/@PCCOEGeeksforGeeks") }>
                <h1 className="py-2 text-lg font-bold">Youtube</h1>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="currentColor"
                  viewBox="0 0 24 24">
                  <path
                    d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z" />
                </svg>
              </button>
            </div>
          </div>

          <div className="h-[430px] xs:mx-4 lg:mr-18 lg:w-1/2 xs:w-full xs:mt-8 lg:mt-none">
            <iframe
              className="GM xs:w-full h-full xs:p-4 lg:m-none"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3780.273281878333!2d73.76163981685461!3d18.65172882901165!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2b9e76c8fa205%3A0x1b210131915734fd!2sPCCOE%20-%20Pimpri%20Chinchwad%20College%20Of%20Engineering!5e0!3m2!1sen!2sin!4v1692731137585!5m2!1sen!2sin"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
        </div>

        <div className="container w-full align-center items-center flex flex-col">
          <h1 className="xs:w-7/8 lg:w-5/6 xs:text-2xl lg:text-4xl font-bold xs:mt-4 lg:mt-32 text-center">Connect with Us: Your Thoughts Matter!</h1>
        </div>

        {/* Form and image section */}
        <div className="flex flex-col align-center mx-auto items-center">
          {/* Form  */}
          <div className="bg-white drop-shadow-2xl	rounded-md h-[85%] flex flex-col p-12 xs:m-4 lg:m-12 h-fit xs:w-full lg:w-5/6">
            <div className="grid gap-6 mb-6 md:grid-cols-2">
              <div>
                <label htmlFor="first_name" className="block mb-2 text-xl text-black pb-2">First name</label>
                <input type="text" id="first_name" className="py-4 bg-gray-50 border border-white-300 text-white-900 text-lg rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 dark:bg-white-700 dark:border-white-600 dark:placeholder-white-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter First Name" required />
              </div>
              <div>
                <label htmlFor="first_name" className="block mb-2 text-xl text-black pb-2">Last name</label>
                <input type="text" id="first_name" className="py-4 bg-gray-50 border border-white-300 text-white-900 text-lg rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 dark:bg-white-700 dark:border-white-600 dark:placeholder-white-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter Last Name" required />
              </div>
            </div>

            <div className="mb-6">
              <label htmlFor="first_name" className="block mb-2 text-xl text-black pb-2">Email</label>
              <input type="text" id="first_name" className="py-4 bg-gray-50 border border-white-300 text-white-900 text-lg rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 dark:bg-white-700 dark:border-white-600 dark:placeholder-white-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter Email" required />
            </div>

            <div className="mb-6">
              <label htmlFor="first_name" className="block mb-2 text-xl text-black pb-2">Phone Number</label>
              <input type="text" id="first_name" className="py-4 bg-gray-50 border border-white-300 text-white-900 text-lg rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 dark:bg-white-700 dark:border-white-600 dark:placeholder-white-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter Phone Number" required />
            </div>

            <div className="mb-6">
              <label htmlFor="first_name" className="block mb-2 text-xl text-black pb-2">Subject</label>
              <input type="text" id="first_name" className="py-4 bg-gray-50 border border-white-300 text-white-900 text-lg rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 dark:bg-white-700 dark:border-white-600 dark:placeholder-white-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Subject (e.g., Event Inquiry, Event Collaboration, Feedback)" required />
            </div>

            <div className="mb-6">
              <label htmlFor="first_name" className="block mb-2 text-xl text-black pb-2">Description</label>
              <textarea type="text" id="first_name" className="py-4 bg-gray-50 border border-white-300 text-white-900 text-lg rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 dark:bg-white-700 dark:border-white-600 dark:placeholder-white-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Please provide a detailed description of your inquiry or message." required />
            </div>

            {/* Submit Button */}  
            <button type="submit" className="mt-12 mx-auto w-content bg-gfg-green rounded-xl flex flex-col justify-center shadow-md translate-y-0 ease-in duration-200 hover:-translate-y-1 hover:shadow-2xl cursor-pointer xs:px-16 lg:px-24 xs:py-4 lg:py-6">
              <div className="text-center text-white font-bold mx-auto">
                Send
              </div>
            </button>
          </div>

        </div>

        {/* Three Content Div */}
      </FadeInSection>

      {/* Back to top button */}
      <div className="flex flex-row justify-center xs:mt-24 lg:my-36">
        <button
          className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded-full shadow"
          onClick={scrollToTop}>
          Back to Top
        </button>
      </div>
      <Footer/>
    </>
  );
};

export default ContactPage;


<div className="flex flex-row justify-around h-1/3 w-screen my-48">
  {/* Location */}
  <div className="h-1/5 w-1/5 bg-contrast-blue text-white rounded-xl shadow-xl">
    <div className="flex flex-col justify-center items-center">
      <LocationSVG className="w-28 h-28 mt-16" />
      <p className="w-4/5 mt-8 mb-16 text-center">
        Sector 26, Pradhikaran, Nigdi, Pune, 411044 (Near Akurdi Railway
        Station)
      </p>
    </div>
  </div>

  {/* Phone */}
  <div className="h-1/5 w-1/5 bg-contrast-blue text-white rounded-xl shadow-xl">
    <div className="flex flex-col justify-center items-center">
      <Phonesvg className="w-28 h-28 mt-16" />
      <div className="w-4/5 mt-8 mb-16 text-center">
        +911234567890
        <p>+911234567890</p>
      </div>
    </div>
  </div>

  {/* Mail */}
  <div className="h-1/5 w-1/5 bg-contrast-blue text-white rounded-xl shadow-xl">
    <div className="flex flex-col justify-center items-center">
      <Mailsvg className="w-28 h-28 mt-16" />
      <p className="w-4/5 mt-8 mb-16 text-center">
        Sector 26, Pradhikaran, Nigdi, Pune, 411044 (Near Akurdi Railway
        Station)
      </p>
    </div>
  </div>
</div>

{/* Image */}
{/* <div className="w-1/2 h-4/5"></div> */}
{/* <img src={require("../../assets/Get.gif")} alt="" /> */}