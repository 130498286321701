import React, { useState, useEffect } from "react";
import "./Header.css";
import { Link } from "react-router-dom";

export default function Header() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useEffect(() => {
    // Function to handle the completion of the dropdown animation
    const handleDropdownComplete = () => {
      // Get the checkbox element
      const toggleCheckbox = document.getElementById("toggle");

      // Toggle the checkbox based on the drawer state
      toggleCheckbox.checked = isDrawerOpen;
    };

    // Add an event listener to the dropdown element to listen for the end of the animation
    const dropdownDrawer = document.querySelector(".dropdown-drawer");
    dropdownDrawer.addEventListener("animationend", handleDropdownComplete);

    // Clean up the event listener when the component unmounts
    return () => {
      dropdownDrawer.removeEventListener(
        "animationend",
        handleDropdownComplete
      );
    };
  }, [isDrawerOpen]);

  const handleHamburgerClick = () => {
    setIsDrawerOpen((prevState) => !prevState);
  };

  return (
    <nav className="navbar">
      <div className="nav-left">
        <a href="/"><img
          className="w-12 h-12 rounded-full ml-4 mr-2"
          src={require("../../assets/gfg-student-chapter-logo.png")}
          alt="GFG Logo"

        /></a>
        <div className="nav-logo-txt text-lg xs:hidden lg:block">
          <h2>PCCOE's GeeksforGeeks Student Chapter</h2>
        </div>
      </div>

      <input type="checkbox" id="toggle" className="toggle-checkbox" />
      <label
        className="toggle-label"
        htmlFor="toggle"
        onClick={handleHamburgerClick}>
        <span className="toggle-icon"></span>
      </label>

      <div className="nav-right">
        <ul className="tabs">
          <li className="nav-item">
            <Link to="/">HOME</Link>
          </li>
          <li className="nav-item">
            <Link to="/about">ABOUT</Link>
          </li>
          <li className="nav-item">
            <Link to="/team">TEAM</Link>
          </li>
          {/* <li className="nav-item">
            <Link to="/events">EVENTS</Link>
          </li> */}
          <li className="nav-item">
            <Link to="/contact">CONTACT</Link>
          </li>
          <li className="nav-item">
            <Link to="/hackmatrix">HACK MATRIX</Link>
          </li>
        </ul>

        {/* <button className="signin-button">Sign In</button> */}
      </div>

      <ul className={`dropdown-drawer ${isDrawerOpen ? "isOpen" : ""}`}>
        <li>
          <Link to="/">HOME</Link>
        </li>
        <li>
          <Link to="/about">ABOUT</Link>
        </li>
        <li>
          <Link to="/team">TEAM</Link>
        </li>
        {/* <li>
          <Link to="/events">EVENTS</Link>
        </li> */}
        <li>
          <Link to="/contact">CONTACT</Link>
        </li>
        <li>
          <Link to="/hackmatrix">HACK MATRIX</Link>
        </li>
        {/* <li>
          <Link to="/signin">SIGN IN</Link>
        </li> */}
      </ul>
    </nav>
  );
}

