import React, { useEffect, useState, useRef,  createRef } from "react";
import TeamCard from "../../Components/team_card/index.jsx";
import FadeInSection from "../../utils/fade_in_section";
import Header from "../../Components/Header/Header.js";
import Footer from "../../Components/Footer/Footer.js";

import axios from "axios";

const TeamPage = () => {
  const videoRef = useRef(null);
  const scrollSectionRef = useRef(null);
  const [team, setTeam] = useState([]);

  const ref1 = createRef();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
    });
  };

  
  const baseURL = "https://gfgweb-backend.vercel.app/api/team/all";

  // for testing purposes
  useEffect(() => {
    const playbackConst = 60; // Adjust the constant as needed

    axios.get(baseURL).then((response) => {
      setTeam( response.data['result'] );
    });

    // Use requestAnimationFrame for smooth playback
    function scrollPlay() {
      if (videoRef.current) {
        // setIllustrationHeight( 60 + parseInt( (parseInt(document.body.getBoundingClientRect().top) * -1) / 20 ) );
        const frameNumber =
          (parseInt(document.body.getBoundingClientRect().top) * -1) /
          playbackConst;
        videoRef.current.currentTime = frameNumber;
      }
      window.requestAnimationFrame(scrollPlay);
    }

    window.requestAnimationFrame(scrollPlay);
  
    const video = videoRef.current;

    return () => {
      video.removeEventListener("loadedmetadata", () => {});
    };
  }, []);

  const filteredMembers = team.filter(member => member.priority === 1 || member.priority === 2);
  const otherMembers = team.filter(member => member.priority > 2 );

  return (
    <>
      <Header/> 
      {/* container containing all the aboslute divs */}
      <div className="absolute absolute-container" ref={ref1}>
        {/* background gradient circles */}
        <div className="absolute -left-32 -top-48 w-[691.78px] h-[660.39px] bg-gfg-green origin-top-left rounded-full blur-[650px] -z-10 xs:hidden lg:block" />
        {/* end of background gradient circles */}
      </div>
      <FadeInSection>
        <div id="unique" className="flex flex-col items-center">
          {/* Row containing headline and animated illustration */}
          <div className="w-5/6 flex flex-row text-center items-center justify-between mt-24 mb-16 xs:flex-col xs:items-center 2xl:flex-row">
            <div className="flex flex-col text-center xs:items-center 2xl:items-start">
              <h1 className="w-5/6 xs:text-3xl lg:text-5xl font-extrabold xs:mt-4 lg:mt-24">
                Meet our team of creators, <span>designers</span>, and problem
                solvers!
              </h1>
              <div className="w-5/6  text-center xs:mt-12 lg:mt-24 mb-16">
                <h1 className="xs:text-lg lg:text-2xl">
                  Geeks by heart! Geeks by brain. Our diverse team of skilled
                  professionals combines strategic thinking, meticulous
                  planning, and flawless execution to turn ideas into reality.
                  With a shared commitment to excellence, we tackle challenges
                  head-on, pushing boundaries and delivering exceptional
                  results.
                </h1>
              </div>
            </div>

            <video ref={videoRef} className="w-2/5 sticky xs:hidden xl:block">
              <source
                type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'   
                src={require("../../assets/team-120.webm")}></source>
            </video>
          </div>

          <div
            ref={scrollSectionRef}
            className="flex flex-col items-center text-center w-full">
            <h1 className="w-full text-5xl font-extrabold my-8">Our Team</h1>
{/* 
            <div className="flex flex-row w-4/5 my-4 justify-center gap-x-12">
              <TeamCard title="Dr. Anuradha Thakare" designation="Head of Department" imageName="thakare_maam.jpeg"/>
              <TeamCard title="Prof. Pallavi Dhade" designation="Teacher Coordinator" imageName="pallavi_maam.jpeg"/>
            </div> */}

            <div className="flex flex-row flex-wrap w-4/5 my-4 gap-x-64 gap-y-8 justify-center">
              { filteredMembers.map( member => <TeamCard title={member.name} designation={member.designation} imageName={member.image} linkedin_url={member.linkedin} /> ) }
            </div>

            <div className="flex flex-row flex-wrap gap-x-12 gap-y-8 w-4/5 my-4 justify-around">
              { otherMembers.map( member => <TeamCard title={member.name} designation={member.designation} imageName={member.image} linkedin_url={member.linkedin} /> ) }
            </div> 
          </div>
        </div>

        {/* Back to top button */}
        <div className="flex flex-row justify-center my-12">
          <button
            className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded-full shadow"
            onClick={scrollToTop}>
            Back to Top
          </button>
        </div>
      </FadeInSection>
      <Footer/>
    </>
  );
};

export default TeamPage;
