import React from "react";
import { ReactComponent as LinkedinIcon } from "../../assets/linkedin.svg";

const TeamCard = ({ title, designation, imageName, linkedin_url }) => {
  return (
    <div className="grid grid-cols-1 items-start py-4 shadow-md w-[350px] select-none ease-in duration-200 rounded-xl hover:shadow-xl overflow-hidden">
      <div className="row-start-1 col-start-1 bg-[#141414] xs:w-max-content aspect-[3/4] rounded-t-xl opacity-0 ease-in duration-200 hover:opacity-60">
        <div className="flex flex-row justify-around items-end w-full h-full ease-in duration-200 hover:mt-0 pb-[2rem]">
          {/* <DiscordIcon className="w-12 h-12 fill-white" /> */}
          <a href={linkedin_url}> <LinkedinIcon className="w-12 h-12 fill-white cursor-pointer"/></a>
          {/* <GithubIcon className="w-12 h-12 fill-white" /> */}
          {/* <TwitterIcon className="w-12 h-12 fill-white" /> */}
        </div>
      </div>
      <div className="row-start-1 col-start-1">
        <img src={imageName} className="row-start-1 rounded-t-xl aspect-[3/4] object-cover block align-top mb-full object-top w-full h-full " alt="test" />
        <div className="text-gfg-green text-xl mt-4 font-bold pl-2">{title}</div>
        <div className="text-black text-lg italic pl-2">{designation}</div>
      </div>
    </div>
  );
};

export default TeamCard;
