import React from "react";
import HackathonHeader from "../hacakthonHeader";
import Footer from "../Footer";
import ContactSupportCard from "../contactSupportCard";

const ContactSupportPage = () => {
  const deatils = [
    {
      name: "Gautam Bhagat",
      email: "gautam.bhagat22@pccoepune.org",
      contact: "+91 9970102190",
    },
    {
      name: "Atharv Powalkar",
      email: "atharv.powalkar22@pccoepune.org",
      contact: "+91 9284674008",
    },
    {
      name: "Pranav Kale",
      email: "pranav.kale22@pccoepune.org",
      contact: "+91 7756956788",
    },
    {
      name: "Utkarsh Patil",
      email: "utkarsh.patil22@pccoepune.org",
      contact: "+91 7020391355",
    },
    {
      name: "Yash Sonar",
      email: "yash.sonar21@pccoepune.org",
      contact: "+91 8390671652",
    },
    {
      name: "Koustubh Kulkarni",
      email: "koustubh.kulkarni21@pccoepune.org",
      contact: "+91 9307058374",
    },
  ];

  return (
    <>
      <div className="contact-us-container flex flex-col w-full justify-center items-center border border-slate-200 rounded-xl">
        <flex className="flex flex-row justify-center flex-wrap gap-x-8 my-12 gap-4">
          {deatils.map((member) => {
            return <ContactSupportCard props={member} />;
          })}
        </flex>
      </div>
    </>
  );
};

export default ContactSupportPage;
