import React from "react";
import "./FooterStyles.css"


function Footer() {
  return (
    <footer>
      {/* <div className="top-panel">
      <div className="footer-logo"></div>
      </div> */}
      
      <div className="container">
            <div className="footer-content">
                <h3>Contact Us</h3>
                <p>Email : 
                <a href="mailto:geeksforgeeks@pccoepune.org">  geeksforgeeks@pccoepune.org</a> </p>
                
                <p>Phone: 
                <a href="tel:+919970102190">  +91 99701 02190</a> </p>
                <p>  Address: 
                  <a href="https://maps.app.goo.gl/amr8b3gNfiX6YGVF7">  निगडी मार्ग, near Akurdi Railway Station Road, Sector No. 26, Pradhikaran, Nigdi, Pimpri-Chinchwad, Maharashtra 411044</a>
                </p>
            </div>
            <div className="footer-content">
                <h3>Quick Links</h3>
                 <ul className="list">
                    <li><a className="footer-anchor" href="/">Home</a></li>
                    <li><a className="footer-anchor" href="/about">About</a></li>
                    <li><a className="footer-anchor" href="/team">Team</a></li>
                    {/* <li><a className="footer-anchor" href="https://google.com">Products</a></li> */}
                    <li><a className="footer-anchor" href="/contact">Contact</a></li>
                 </ul>
            </div>
            <div className="footer-content">
                <h3>Follow Us</h3>
                <ul className="social-icons">
                 <li><a className="footer-anchor" href="https://www.linkedin.com/company/gfgpccoe"><i className="bx bxl-linkedin"></i></a></li>
                 <li><a className="footer-anchor" href="https://www.instagram.com/gfg_pccoe/"><i className="bx bxl-instagram"></i></a></li>
                 <li><a className="footer-anchor" href="https://twitter.com/gfg_pccoe"><i className="bx bxl-twitter"></i></a></li>
                 <li><a className="footer-anchor" href="https://www.youtube.com/@PCCOEGeeksforGeeks"><i className="bx bxl-youtube"></i></a></li>
                </ul>
                </div>
      </div>
      <div className="bottom-bar">
            <p>&copy; 2024 PCCOE's GeeksforGeeks Student Chapter. All rights reserved</p>
      </div>
    </footer>
  );
}

export default Footer;