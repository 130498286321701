export const PS =  [
    {
      "_id": "65c89f097e7f0dbec2a6a186",
      "track": "Education and Technology",
      "title": "Develop a Solution for improving Higher Education Exploration with an Integrated Information Platform.",
      "description": "Develop a user-friendly platform that acts as a centralized hub for students who need information on universities, courses, admission processes, faculty details, alumni experiences, career paths, and employer partnerships. This solution is expected to provide filters, a contact form that will provide direct communication with university representatives, and a bookmarking system for users to save preferred universities and courses. The primary aim is to simplify and enhance the higher education exploration process, reducing the information gap, simplifying the application process, and helping students in their pursuit of academic and career success.",
      "psid": "ET01",
      "__v": 0
    },
    {
      "_id": "65c89f3c27fde8f26723d505",
      "track": "Education and Technology",
      "title": "Develop a streamlined platform for addressing the lack of personalized mentorship using efficient mentor matching, and ensuring tailored and effective mentorship experiences. ",
      "description": "The solution should help solve the lack of mentorship in education. Many students struggle to find mentors aligned with their goals and interests. Existing systems often lack efficiency and user-friendliness, affecting mentor-student connections. Because there is not any such platform, students cannot get guidance specific to their individual needs. This problem requires the development of a system that allows students to easily input their preferences and provides a smoother process for mentor matching, ensuring personalized, effective mentorship experiences for educational and career growth.",
      "psid": "ET02",
      "__v": 0
    },
    {
      "_id": "65c89f5727fde8f26723d50a",
      "track": "Education and Technology",
      "title": "Design a solution to improve the focus of students and enhance attention span while studying.",
      "description": "Develop a solution to address the challenge of maintaining focus and improving attention spans within educational environments. Develop a solution that uses interactive methods, or other creative approaches to improve the learning experience and allow continuous concentration among students. Create an environment that helps make education effective and keeps the knowledge for a longer time.",
      "psid": "ET03",
      "__v": 0
    },
    {
      "_id": "65c89f9327fde8f26723d511",
      "track": "Education and Technology",
      "title": "Develop a platform for efficient college community management, volunteering Recruitment, and Meaningful Connections.",
      "description": "Develop a  platform for college clubs and communities to manage their organizations and events, recruit new volunteers, and engage with other communities. The platform should offer a seamless way for communities to discover and connect with like-minded volunteers by finding organizations or their interests. Additionally, it should allow volunteers to explore various communities and connect with their preferred community.",
      "psid": "ET04",
      "__v": 0
    },
    {
      "_id": "65c8a03d27fde8f26723d516",
      "track": "Food and Healthcare",
      "title": "Develop a solution that can be used to promote mental health and well-being, providing support and resources for individuals facing mental health challenges. ",
      "description": "A lot of people face barriers such as societal stigma, limited resources, and insufficient awareness when fighting against mental health challenges. This causes everyday stress which further complicates the situation. The existing support systems are often divided and may not adequately address the population's diverse needs. The problem statement expects a solution that can help users overcome social barriers to access by providing effective support and resources for individuals facing these issues. Incorporating a chat functionality into the solution would enhance user engagement and overall effectiveness in promoting mental health and well-being.",
      "psid": "FH01",
      "__v": 0
    },
    {
      "_id": "65c8a05527fde8f26723d51b",
      "track": "Food and Healthcare",
      "title": "Develop a solution that helps individuals track their daily nutritional intake and provides personalized recommendations for a healthier lifestyle.",
      "description": "In today's fast-paced lifestyle people are struggling to manage their daily nutritional intake, which is causing adverse effects on their overall health and well-being. Many people face challenges in maintaining a balanced diet due to limited time, and insufficient nutritional awareness. It is also difficult to understand the complex dietary information. All of this contributes to a range of health issues, including nutritional deficiencies, weight management problems, and a higher risk of chronic diseases. There is a need for a solution that allows people to easily track their nutritional intake and receive personalized recommendations, improving their health and avoiding the adverse effects associated with inadequate dietary habits.",
      "psid": "FH02",
      "__v": 0
    },
    {
      "_id": "65c8a08227fde8f26723d520",
      "track": "Food and Healthcare",
      "title": "Develop a solution to safeguard the security and privacy of Electronic Health Records (EHRs) to keep sensitive patient information safe. ",
      "description": "As the healthcare industry increasingly relies on digital platforms for storing patient information it is important to store the patient data securely. The solution should be able to store health information such that unauthorized access and manipulation is not possible. The integrity of patient data should be maintained to help increase confidence in the privacy of health records.",
      "psid": "FH03",
      "__v": 0
    },
    {
      "_id": "65c8a09827fde8f26723d525",
      "track": "Food and Healthcare",
      "title": "Develop a solution to effectively manage and redistribute excess or unused food from hotels and restaurants, contributing to sustainable practice. ",
      "description": "A huge amount of edible food is wasted in hotels and restaurants, contributing to environmental issues and resource wastage. The problem statement expects a system that allows organized management and redistribution of excess food to those in need. This would help address social and environmental concerns by distributing excess resources towards sustainable and impactful initiatives. The goal is to establish an effective, scalable framework that encourages optimal food management and contributes to a more sustainable food ecosystem.",
      "psid": "FH04",
      "__v": 0
    },
    {
      "_id": "65c8a0c827fde8f26723d52d",
      "track": "Welfare and Awareness",
      "title": "Develop a solution to reduce the usage of private transportation methods and increase the use of shared mobility.",
      "description": "Private transportation methods in urban cities are a major reason for traffic congestion and environmental pollution. The solution should encourage shared transportation options, such as carpooling, ride-sharing, and public transportation to reduce the impact of private transport vehicles. The solution can provide incentives for preferring public transport over private transport. ",
      "psid": "WA01",
      "__v": 0
    },
    {
      "_id": "65c8a0e627fde8f26723d532",
      "track": "Welfare and Awareness",
      "title": "Develop a solution that improves awareness and preparedness for natural disasters, providing approved emergency procedures for disasters, and emergency contacts.",
      "description": " Natural disasters are sudden and unknown due to which there is a lack of awareness and preparedness often increases their impact. The problem statement expects a solution that not only improves public awareness about various types of natural disasters but also provides approved emergency procedures and readily available emergency contacts to ensure swift and effective responses in times of crisis.",
      "psid": "WA02",
      "__v": 0
    },
    {
      "_id": "65c8a0fb27fde8f26723d537",
      "track": "Welfare and Awareness",
      "title": "Develop a solution to improve financial awareness among people, using visualizations, and providing methods to increase savings.",
      "description": "Develop a solution that improves users’s awareness regarding money savings. The solution can contain dashboards for easy understanding of their user’s spending and savings. The solution should allow users to create and manage categories for their expenses (food, health, entertainment, etc.) and provide visualization of this data.",
      "psid": "WA03",
      "__v": 0
    },
    {
      "_id": "65c8a13a27fde8f26723d53c",
      "track": "Welfare and Awareness",
      "title": "Create an easy-to-use platform that helps communities plan and track local clean-up events, allowing volunteers to sign up, log collected waste, and visualize the impact, fostering a cleaner environment.",
      "description": "Communities worldwide are increasingly recognizing the importance of environmental supervision, and organizing local clean-up events plays a crucial role in fostering a cleaner and healthier environment. However, there is a need for a user-friendly platform that streamlines the process of planning and tracking these events while engaging volunteers effectively. The solution should include an intuitive and easy-to-use platform dedicated to community clean-up initiatives. This platform aims to empower communities to seamlessly plan and coordinate local clean-up events, simplifying the organization process for both event organizers and volunteers.",
      "psid": "WA04",
      "__v": 0
    }
  ]