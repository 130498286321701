import React from "react";
import logo from "../../assets/gfg-student-chapter-logo.png";
import { useState, useEffect } from "react";
import { PAYLOAD } from "../../utils/constants";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import EditTeamPopup from "../editTeamPopup";
import calculateTimeDifference from "../../utils/timeDifference";

const TeamInfoBar = () => {
  const initialTime = calculateTimeDifference(new Date(), new Date("2024-02-18T14:40:00")) / 1000;
  const [timeRemaining, setTimeRemaining] = useState(initialTime);
  const [showModal, setShowModal] = useState(false);
  const [startTimer, setStartTimer] = useState();

  useEffect(() => {
    setStartTimer(
      calculateTimeDifference(new Date(), new Date("2024-02-17T11:00:00")) <= 0
    );

    const timerInterval = setInterval(() => {
      setTimeRemaining((timeRemaining) => {
        if (timeRemaining === 0) {
          clearInterval(timerInterval);

          // Perform actions when the timer reaches zero
          setStartTimer(false);

          return 0;
        } else {
          return timeRemaining - 1;
        }
      });
    }, 1000);

    // Cleanup the interval when the component unmounts
    return () => clearInterval(timerInterval);
  }, []); // The empty dependency array ensures the effect runs only once on mount

  // Convert seconds to hours, minutes, and seconds
  var seconds = Math.floor(timeRemaining);
  const days = Math.floor(seconds / (3600 * 24));
  seconds -= days * 3600 * 24;

  const hours = Math.floor(seconds / 3600);
  seconds -= hours * 3600;

  const minutes = Math.floor(seconds / 60);
  seconds -= minutes * 60;

  const payload = JSON.parse(localStorage.getItem(PAYLOAD));
  // //console.log(payload)
  const teamId = payload.username.split("_")[0].toString().padStart(4, "0");
  let leader = payload.members.filter((member) => {
    return member.role === "Leader";
  });

  //// console.log(leader);
  leader = leader[0]["participant"];
  // console.log(leader);

  return (
    <div className="w-full mt-5 p-5 mx-auto flex xl:flex-row s:flex-col xl:items-stretch s:items-center">
      <div className="xl:w-[15vw] s:w-[80vw] my-auto">
        <img
          src={logo}
          alt=""
          className="p-5 border boder-slate-200 aspect-square"
        />
      </div>
      <div className="xl:m-5 s:m-0 flex flex-col flex-grow items-stretch justify-between">
        <div className="flex xl:flex-row s:flex-col-reverse xl:items-start s:items-end justify-start flex-grow edit-icon-container p-2 rounded-xl mt-4">
          <table className="table-auto text-2xl border-separate border-spacing-3 flex-grow">
            <tr>
              <td className="font-bold">Team ID</td>
              <td className="font-bold">: </td>
              <td>{payload.teamid}</td>
            </tr>
            <tr>
              <td className="font-bold">Team Name</td>
              <td className="font-bold">:</td>
              <td>{payload.name}</td>
            </tr>
            <tr>
              <td className="font-bold">Leader Name</td>
              <td className="font-bold">:</td>
              <td> {`${leader.firstName} ${leader.lastName}`}</td>
            </tr>
            <tr>
              <td className="font-bold w-[250px]">Leader's College</td>
              <td className="font-bold ">:</td>
              <td>{leader.college}</td>
            </tr>
          </table>
          <button id="open-dialog" onClick={() => setShowModal(true)}>
            <i className="bx bx-edit text-4xl text-red-600"></i>
          </button>
        </div>

        <div
          className={
            "container flex flex-grow justify-end items-end " +
            (startTimer ? "block" : "hidden")
          }
        >
          {/* <h1 className="font-bold text-4xl text-right">
            {`Time Remaining ${hours}h:${minutes}m:${seconds}s`}
        </h1> */}
        </div>
      </div>

      <EditTeamPopup
        trigger={showModal}
        onClose={() => setShowModal(false)}
        payload={payload}
      />
    </div>
  );
};

export default TeamInfoBar;
