import React from "react";
import { useState, useEffect } from "react";
import useConfetti from "../Confetti/useConfetti";
import HackathonHeader from "../hacakthonHeader";
import HackMatrixFooter from "../HackMatrixFooter/HackMatrixFooter";

const Finalists = () => {
  const [bestTime, setBestTime] = useState(false);
  const [bestDiceRoll, setBestDiceRoll] = useState(false);
  const { confetti, newBestTime, newBestDiceRoll, newBestGame } = useConfetti({
    bestTime,
    setBestTime,
    bestDiceRoll,
    setBestDiceRoll,
  });

  useEffect(() => {
    confetti();
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  };

  const teams = [
    {
      idx: 0,
      teamid: "HM0008",
      leaderName: "Sahil Bomble",
      teamName: "Akatsuki",
      college: "Pimpri Chinchwad College of Engineering",
      track: "EDUCATION AND TECHNOLOGY",
      status: "SELECTED",
    },
    {
      idx: 1,
      teamid: "HM0052",
      leaderName: "Ryan Rego",
      teamName: "MernMaestros",
      college: "Pimpri Chinchwad College of Engineering",
      track: "EDUCATION AND TECHNOLOGY",
      status: "SELECTED",
    },
    {
      idx: 2,
      teamid: "HM0061",
      leaderName: "Soham Tak",
      teamName: "NEVER_SELECTED",
      college: "Vishwakarma Institute of Technology",
      track: "EDUCATION AND TECHNOLOGY",
      status: "SELECTED",
    },
    {
      idx: 3,
      teamid: "HM0035",
      leaderName: "Mahesh Kakde",
      teamName: "Techstars",
      college: "Pimpri Chinchwad College of Engineering",
      track: "EDUCATION AND TECHNOLOGY",
      status: "SELECTED",
    },
    {
      idx: 4,
      teamid: "HM0055",
      leaderName: "Rahul Shah",
      teamName: "PANDAVAS",
      college: "D.Y Patil International University",
      track: "EDUCATION AND TECHNOLOGY",
      status: "STANDBY",
    },
    {
      idx: 5,
      teamid: "HM0023",
      leaderName: "Himanshu Shedam",
      teamName: "SkyNet",
      college: "Vishwakarma Institute of information Technology",
      track: "EDUCATION AND TECHNOLOGY",
      status: "STANDBY",
    },
    {
      idx: 6,
      teamid: "HM0034",
      leaderName: "Samarth Hapse",
      teamName: "404 NOT FOUND",
      college: "JSPM Rajarshi Shahu College Of Engineering",
      track: "FOOD AND HEALTHCARE",
      status: "SELECTED",
    },
    {
      idx: 7,
      teamid: "HM0060",
      leaderName: "Vikrant Khedkar",
      teamName: "CodeBlockers",
      college: "Dr. D.Y. Patil Institute of Engineering and Research",
      track: "FOOD AND HEALTHCARE",
      status: "SELECTED",
    },
    {
      idx: 8,
      teamid: "HM0033",
      leaderName: "Nutan Gaikwad",
      teamName: "CodeZen",
      college: "D.Y.Patil College of Engineering, Pune",
      track: "FOOD AND HEALTHCARE",
      status: "SELECTED",
    },
    {
      idx: 9,
      teamid: "HM0045",
      leaderName: "Vaidehi Bhonge",
      teamName: "Equal_Essence",
      college: "Pimpri Chinchwad College of Engineering",
      track: "FOOD AND HEALTHCARE",
      status: "SELECTED",
    },
    {
      idx: 10,
      teamid: "HM0040",
      leaderName: "Tanmay Shindkar",
      teamName: "Team Hunter",
      college: "Pimpri Chinchwad College of Engineering",
      track: "FOOD AND HEALTHCARE",
      status: "STANDBY",
    },
    {
      idx: 11,
      teamid: "HM0048",
      leaderName: "Radhey Kedar",
      teamName: "FoodGuardian",
      college: "Pimpri Chinchwad College of Engineering",
      track: "FOOD AND HEALTHCARE",
      status: "STANDBY",
    },
    {
      idx: 12,
      teamid: "HM0024",
      leaderName: "Prithviraj More",
      teamName: "Himalaya",
      college: "Pimpri Chinchwad College of Engineering",
      track: "WELFARE AND AWARENESS",
      status: "SELECTED",
    },
    {
      idx: 13,
      teamid: "HM0022",
      leaderName: "Sarthak Kshirsagar",
      teamName: "JustInCase",
      college: "Pimpri Chinchwad College of Engineering",
      track: "WELFARE AND AWARENESS",
      status: "SELECTED",
    },
    {
      idx: 14,
      teamid: "HM0025",
      leaderName: "Mandip Bhattarai",
      teamName: "Scorpions",
      college: "Pimpri Chinchwad College of Engineering",
      track: "WELFARE AND AWARENESS",
      status: "SELECTED",
    },
    {
      idx: 15,
      teamid: "HM0056",
      leaderName: "Siddhi Bajpai",
      teamName: "Algorithm Avengers",
      college: "Pimpri Chinchwad College of Engineering",
      track: "WELFARE AND AWARENESS",
      status: "SELECTED",
    },
    {
      idx: 16,
      teamid: "HM0049",
      leaderName: "Prathamesh Lohar",
      teamName: "TECH_TITANS",
      college: "Pimpri Chinchwad College of Engineering",
      track: "WELFARE AND AWARENESS",
      status: "STANDBY",
    },
    {
      idx: 17,
      teamid: "HM0027",
      leaderName: "Suyash Lagad",
      teamName: "Binary Brains",
      college:
        "Dr. D.Y. Patil Institute of Engineering, Management and Research",
      track: "WELFARE AND AWARENESS",
      status: "STANDBY",
    },
  ];

  return (
    <>
      <HackathonHeader className="pb-2" />
      <div class="flex flex-col w-[90%] mx-auto xl:p-12 s:p-2 shadow-xl rounded-xl border border-slate-200 mt-[4%] items-center">
        <h1 className="font-bold xl:text-[2.5vw] s:text-[9vw] text-center xl:mb-12 xl:mt-0 s:mt-8 s:mb-8">
          {" "}
          Hack Matrix Finalists
        </h1>

        <div class="w-[100%] py-2 flex flex-col items-center inline-block sm:px-6 lg:px-8">
          <div class="w-[100%] my-auto overflow-x-scroll">
            <table class="w-[100%] rounded-xl mx-auto">
              <thead class="bg-white border-b">
                <tr className="border border-slate-400">
                  <th
                    scope="col"
                    class="text-xl text-center font-bold text-gray-900 px-6 py-4 text-left">
                    Track
                  </th>
                  <th
                    scope="col"
                    class="text-xl text-center font-bold text-gray-900 px-6 py-4 text-left">
                    Team ID
                  </th>
                  <th
                    scope="col"
                    class="text-xl text-center font-bold text-gray-900 px-6 py-4 text-left">
                    Team Name
                  </th>
                  <th
                    scope="col"
                    class="text-xl text-center font-bold text-gray-900 px-6 py-4 text-left">
                    Leader Name
                  </th>
                  <th
                    scope="col"
                    class="text-xl text-center font-bold text-gray-900 px-6 py-4 text-left">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody >
                {teams.map((team) => {
                  return (
                    <tr class="border border-slate-400 cursor-pointer hover:scale-[1.03] transition duration-500">
                      {team.idx % 6 === 0 ? (
                        <td
                          rowSpan={6}
                          class="px-6 py-4 text-center font-bold whitespace-nowrap text-xl text-gray-900">
                          {team.track}
                        </td>
                      ) : (
                        ""
                      )}
                      <td class="text-xl text-gray-900 font-light px-6 py-4 text-center whitespace-nowrap">
                        {team.teamid}
                      </td>
                      <td class="text-xl text-gray-900 font-light px-6 py-4 text-center whitespace-nowrap">
                        {team.teamName}
                      </td>
                      <td class="text-xl text-gray-900 font-light px-6 py-4 text-center whitespace-nowrap">
                        {team.leaderName}
                      </td>
                      <td class="text-xl text-gray-900 font-light px-6 py-4 text-center whitespace-nowrap">
                        {team.status}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <h2 className="text-xl my-8">
            Note: Teams that are in <strong> "STANDBY" </strong> can be selected
            as Finalists if any of the shortlisted teams from respective tracks
            are unable to participate for Round-2. In that case, organizing team will reach to you!
          </h2>
        </div>
      </div>

      {/* Back to top button */}
      <div className="block w-full flex flex-col justify-center items-center">
        <div className="xs:mt-12 w-fit">
          <button
            className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded-full shadow xs:text-sm sm:text-lg"
            onClick={scrollToTop}>
            Back to Top
          </button>
        </div>
      </div>

      <HackMatrixFooter />
    </>
  );
};

export default Finalists;
