import { useState, useRef, useEffect } from "react";

const FadeInSection = (props) => {
  const [isVisible, setVisible] = useState(false);
  const domRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setVisible(entry.isIntersecting);
        }
      });
    });

    const curRef = domRef.current;
    observer.observe(curRef);

    return () => observer.unobserve(curRef);
  }, [isVisible]);

  const cl = isVisible ? "motion-safe:animate-fadeIn" : "";
  return (
    <div className={cl} ref={domRef}>
      {props.children}
    </div>
  );
}

export default FadeInSection;
