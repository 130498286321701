import React, { useEffect, useState } from "react";
import {
  PAYLOAD,
  BACKEND_URL,
  HACKTEAMTOKEN,
  HEALTHCARE_TRACK,
  WELFARE_TRACK,
  EDUCATION_TRACK,
  SUBMISSION,
} from "../../utils/constants";
import { PS } from "../../utils/Ps";
import calculateTimeDifference from "../../utils/timeDifference";

const initialGithubRepo = [{ description: "", url: "" }];
const initialVideoLink = "";
const initialDeployedLink = "";
const initialSelectedTrack = "";
const initialSelectedPS = "";
const submission = localStorage.getItem(SUBMISSION) === "true";

function SubmissionTab() {
  const tracks = [EDUCATION_TRACK, HEALTHCARE_TRACK, WELFARE_TRACK];
  const [selectedTrack, setSelectedTrack] = useState("");
  const [selectedPS, setSelectedPS] = useState("");
  const [ps, setPS] = useState(PS);
  const [FH, setFH] = useState([]);
  const [ET, setET] = useState([]);
  const [WA, setWA] = useState([]);
  const [psDrop, setPSDrop] = useState([]);
  const [timeRemaining, setTimeRemaining] = useState(
    calculateTimeDifference(new Date(), new Date("2024-02-18T14:40:00")) / 1000
  );

  const fetchPS = async () => {
    let sorted = ps.filter((item) => {
      return item.track === HEALTHCARE_TRACK;
    });
    setFH(sorted);

    sorted = ps.filter((item) => {
      return item.track === EDUCATION_TRACK;
    });
    setET(sorted);

    sorted = ps.filter((item) => {
      return item.track === WELFARE_TRACK;
    });
    setWA(sorted);
    // let res = await fetch(`${BACKEND_URL}/api/hack/ps`, {
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/json",
    //     token: localStorage.getItem(HACKTEAMTOKEN),
    //   },
    // });

    // res = await res.json();

    // console.log(ps)
    // if (res.success === 1) {

    //   let sorted = ps.filter((item) => {
    //     return item.track === HEALTHCARE_TRACK;
    //   })
    //   setFH(sorted);

    //   sorted = ps.filter((item) => {
    //     return item.track === EDUCATION_TRACK;
    //   })
    //   setET(sorted)

    //   sorted = ps.filter((item) => {
    //     return item.track === WELFARE_TRACK;
    //   })
    //   setWA(sorted)

    //   return;
    // }

    // alert("Error in fetching Problem Statements!")
  };

  useEffect(() => {
    fetchPS();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [githubRepo, setGithubRepo] = useState([{ description: "", url: "" }]);

  const addRepo = (e) => {
    e.preventDefault();
    setGithubRepo([...githubRepo, { description: "", url: "" }]);
  };

  const removeRepo = (e, index) => {
    // e.preventDefault();
    if (githubRepo.length > 1) {
      let updateRepo = [...githubRepo];
      updateRepo.splice(index, 1);
      setGithubRepo(updateRepo);
    }
  };

  const handleChange = (e) => {
    //console.log(e.target.value)
    setSelectedTrack(e.target.value);
    setPSDrop([]);
    if (e.target.value === EDUCATION_TRACK) {
      setPSDrop(ET);
    } else if (e.target.value === HEALTHCARE_TRACK) {
      setPSDrop(FH);
    } else if (e.target.value === WELFARE_TRACK) {
      setPSDrop(WA);
    } else {
      setPSDrop([]);
    }
  };

  const payload = JSON.parse(localStorage.getItem(PAYLOAD));

  const [videoLink, setVideoLink] = useState("");
  const [deployedLink, setDeployedLink] = useState("");

  const handleGithubChange = (e, i) => {
    const { name, value } = e.target;
    const updatedRepos = githubRepo.map((repo, index) => {
      if (index === i) {
        return { ...repo, [name]: value };
      }
      return repo;
    });
    setGithubRepo(updatedRepos);
  };

  const handleSubmission = async (e) => {
    e.preventDefault();
    const body = {
      teamid: payload.teamid,
      track: selectedTrack,
      problemstatement: selectedPS,
      videoLink: videoLink,
      deployedLink: deployedLink,
      githubrepos: githubRepo,
    };

    console.log(body);

    let res = await fetch(`${BACKEND_URL}/api/hack/submit`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem(HACKTEAMTOKEN),
      },
    });

    res = await res.json();
    console.log(res);

    if (res.success === 1) {
      localStorage.setItem(SUBMISSION, true);
      // Reset form
      setSelectedTrack(initialSelectedTrack);
      setSelectedPS(initialSelectedPS);
      setVideoLink(initialVideoLink);
      setDeployedLink(initialDeployedLink);
      setGithubRepo(initialGithubRepo);
      window.location.reload();
      alert("Submission Done");
      return;
    }

    alert("Error in submission");
  };

  // return (
  return !submission ? (
    <div className="p-5 w-3/5 rounded-xl  border mr-2  bg-white">
      <h2 className="text-3xl font-bold">Submission</h2>
      <h2
        className={
          "text-xl my-4 text-red-500 " +
          (timeRemaining > 0 ? " block" : " hidden")
        }>
        {" "}
        ** Submissions will end on 18 February 2:00pm **{" "}
      </h2>
      <div className="w-full  p-4 my-2 rounded-xl">
        <form
          id="submissionform"
          className="w-full  "
          onSubmit={handleSubmission}
          style={{ fontSize: "18px" }}>
          <input type="hidden" name="teamid" value={payload.teamid} />

          <label
            htmlFor="track"
            className="block mb-2 font-semibold   text-gray-900 ">
            Track
          </label>
          <select
            id="track"
            name="track"
            onChange={handleChange}
            required
            className="bg-gray-50 border border-gray-300 text-gray-900  rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
            <option value="">Select Track</option>
            {tracks.map((item) => {
              return (
                <option key={item} value={item}>
                  {item}
                </option>
              );
            })}
          </select>

          <label
            htmlFor="ps"
            className="block mt-4 mb-2 font-semibold   text-gray-900 ">
            Problem Statements
          </label>
          <select
            id="ps"
            required
            onChange={(e) => {
              setSelectedPS(e.target.value);
            }}
            className="bg-gray-50 border border-gray-300 text-gray-900  rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            disabled={timeRemaining < 0}>
            <option value="">Select Problem Statement</option>
            {psDrop.map((item) => {
              return (
                <option key={item.psid} value={item.title}>
                  {item.title}
                </option>
              );
            })}
          </select>

          <div className="mb-6">
            <label
              htmlFor="videourl"
              className="block mb-2 mt-4  font-semibold text-gray-900">
              Video Demonstration
            </label>
            <input
              type="url"
              id="videourl"
              className="bg-gray-50 border border-gray-300 text-gray-900  rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              value={videoLink}
              onChange={(e) => {
                setVideoLink(e.target.value);
              }}
              placeholder="Add Video Access URL"
              required
            />
          </div>
          <div className="mb-6">
            <label
              htmlFor="solutionlink"
              className="block mb-2 mt-4  font-semibold text-gray-900">
              Deployed Solution Link
            </label>
            <input
              type="url"
              id="solutionlink"
              className="bg-gray-50 border border-gray-300 text-gray-900  rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              value={deployedLink}
              onChange={(e) => {
                setDeployedLink(e.target.value);
              }}
              placeholder="Add web or app url of solution"
              required
            />
          </div>
          <div className="mb-6 w-full">
            <div className="flex justify-between items-center">
              <label
                htmlFor="text"
                className="block mb-2  font-semibold text-gray-900">
                Github
              </label>
              <button
                onClick={addRepo}
                className="bg-blue-700 text-white py-2 px-4 rounded-lg">
                Add Repo
              </button>
            </div>
            <div className="w-full">
              {githubRepo.map((item, i) => {
                return (
                  <div className="flex mt-1 justify-around items-center">
                    <div className="w-2/5 mr-4" key={i}>
                      <input
                        type="text"
                        id="first_name"
                        name="description"
                        className="w-full bg-gray-50 border border-gray-300 text-gray-900  rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5"
                        value={githubRepo[i].description}
                        onChange={(e) => {
                          handleGithubChange(e, i);
                        }}
                        placeholder="Repo Desc. Eg: Frontend,Backend"
                        required
                      />
                    </div>
                    <div className="w-2/5 mr-4">
                      <input
                        type="url"
                        id="last_name"
                        className="bg-gray-50 border border-gray-300 text-gray-900  rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        name="url"
                        value={githubRepo[i].url}
                        onChange={(e) => {
                          handleGithubChange(e, i);
                        }}
                        placeholder="Repo URL"
                        required
                      />
                    </div>
                    <div className="">
                      <button
                        className="bg-red-600 text-white p-2  mx-auto my-1 rounded-xl  w-full"
                        onClick={(e) => {
                          removeRepo(e, i);
                        }}
                        disabled={githubRepo.length <= 1}>
                        Remove
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <button
            type="submit"
            className={`bg-green-600 text-white p-4 font-bold rounded-xl mx-auto disabled:bg-gray-300 disabled:text-black w-1/2`}
            disabled={timeRemaining<0}>
            Submit Work
          </button>
        </form>
      </div>
    </div>
  ) : (
    <div className="p-5 xl:w-3/5 s:w-full rounded-xl border mr-2 bg-white flex flex-col items-center justify-center">
      <h1 className="xl:text-[2vw] s:text-[7vw] font-bold xl:w-1/2 xl:w-[80%] s:w-[90%] s:py-24 mx-auto text-center">
        Thank you for your Submission! Results will be announced soon! 
      </h1>
    </div>
  );
}

export default SubmissionTab;
