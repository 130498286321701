import React from 'react'
import { Link } from 'react-router-dom'

function SubmissionGuidelines() {
    return (
        <div className="xl:w-2/5 s:w-full xl:my-0 s:my-4 rounded-xl mx-auto  bg-white">
            <div className='p-4 border rounded-xl'>
                <h2 className="text-2xl font-bold mb-4 ">Submission Guidelines</h2>
                <div className="text-md p-4 text-black-800">
                    <ol className="list-decimal pl-6">
                        <li className="mb-4">
                            <span className="font-semibold">Video Demonstration:</span> Record a concise video demonstrating your solution. Limit the duration to 6 minutes to ensure clarity and focus. Clearly explain the features and functionalities while showcasing practical use cases.
                        </li>
                        <li className="mb-4">
                            <span className="font-semibold">Deployed Solution Link:</span> Provide a link to your deployed solution for practical usage. Ensure the solution is accessible to reviewers and fully functional. Thoroughly test all features to guarantee seamless user experience.
                        </li>
                        <li className="mb-4">
                            <span className="font-semibold">GitHub Repository:</span> Share the source code on GitHub. Maintain a well-organized repository with clear documentation, including a README.md file. Add <a href="https://github.com/gfgpccoe" className="font-bold underline text-normal text-blue-600" target="_blank">@gfgpccoe</a> as a collaborator in each repository. Use descriptive comments and commit messages for easy understanding.
                        </li>
                    </ol>
                    <p className="text-sm italic mt-4">
                        Follow these guidelines to present a clear and comprehensive overview of your solution, facilitating effective evaluation by evaluators.
                    </p>
                </div>
                <div>

                </div>
            </div>
            <div className='p-4 my-2 border rounded-xl'>
            <h2 className="text-2xl font-bold mb-4 ">Resources</h2>
            <div className="text-black-800">
                    <ol className="list-decimal pl-6">
                       <li className='my-2 '><a href="https://youtu.be/mW962ARlUZE?feature=shared" target="_blank" rel="noopener noreferrer"><b className='font-bold'>Github Setup and Tutorial <i class='text-xl font-bold bx bx-link-alt'></i></b></a> : A comprehensive guide to setting up and using GitHub, including repository creation, version control basics, collaboration features, and more.</li>
                       <li className='my-2'><a href="https://www.linkedin.com/feed/update/urn:li:activity:7152176971693588480" target='_blank'><b className='font-semibold'>Github Cheatsheet <i class='text-xl font-bold bx bx-link-alt'></i></b></a>: Quick reference document summarizing commonly used Git commands and GitHub workflows for efficient development and collaboration.</li>
                       <li className='my-2'><a href="https://youtu.be/njaMYKbiGv8?feature=shared" target='_blank'><b className='font-semibold'>How to record video? <i class='text-xl font-bold bx bx-link-alt'></i></b></a> Know how to capture screen recordings.</li>
                      
                    </ol>
                   
                </div>
            </div>
        </div>

    )
}

export default SubmissionGuidelines
