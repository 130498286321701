import React from 'react'
import SubmissionTab from './SubmissionTab'
import SubmissionGuidelines from './SubmissionGuidelines'

function SubmissionIndex() {
  return (
    <div className='  rounded-xl flex xl:flex-row s:flex-col'>
      <SubmissionTab/>
      <SubmissionGuidelines/>
    </div>
  )
}

export default SubmissionIndex
