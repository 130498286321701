import React from 'react'
import Team from './Team'
import HackMatrixFooter from '../HackMatrixFooter/HackMatrixFooter'

function HackTeamMembers() {
  return (
    <div>
      <Team/>
      <HackMatrixFooter/>
    </div>
  )
}

export default HackTeamMembers
