import React from "react";

const ContactSupportCard = ({ props }) => {
  return (
    <div className="details-container flex flex-col items-left rounded-xl border border-slate-200 xl:w-[400px] s:w-[95%] h-fit transition duration-500 cursor-pointer hover:scale-110 hover:bg-emerald-500 hover:text-white">
      <div className="member-1 flex flex-col xl:mx-8 s:mx-2 my-6">
        <h2 className="text-2xl font-bold">{props.name}</h2>

        <div className="text-[1.3vw] sm:text-lg s:text-[4vw] mt-4">
          {props.email}
        </div>
        <h2 className="text-[1.3vw] sm:text-lg s:text-[4vw]">
          {" "}
          Contact: <span className="underline "> {props.contact} </span>{" "}
        </h2>
      </div>
    </div>
  );
};

export default ContactSupportCard;
