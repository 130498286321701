import React from "react";
import { useRef, useState } from "react";
import { BACKEND_URL, HACKTEAMTOKEN, PAYLOAD } from "../../utils/constants";

const EditTeamPopup = (props) => {
  const modalRef = useRef();
  const [oldPassword, setOldPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [name, setName] = useState(props.payload.name);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [details, setDetails] = useState({
    oldpass: "",
    newpass: "",
  });

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setShowChangePassword(false);
      setOldPassword("");
      setNewPassword("");
      props.onClose();
    }
  };

  const updatePassword = async () => {
    let res = await fetch(`${BACKEND_URL}/api/hack/changepassword`, {
      method: "POST",
      body: JSON.stringify({
        teamId: props.payload._id,
        oldPassword: oldPassword,
        newPassword: newPassword,
      }),
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem(HACKTEAMTOKEN),
      },
    });

    res = await res.json();

    if (res.success === 1) {
      alert(res.message);
      props.onClose();
      return;
    }
  };

  const updateName = async () => {
    let res = await fetch(`${BACKEND_URL}/api/hack/changename`, {
      method: "POST",
      body: JSON.stringify({
        name: name,
        teamId: props.payload._id,
      }),
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem(HACKTEAMTOKEN),
      },
    });
    res = await res.json();

    if (res.success === 1) {
      props.payload.name = name;
      localStorage.setItem(PAYLOAD, JSON.stringify(props.payload));
      props.onClose();
      return;
    }

    alert(res.message);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (showChangePassword) {
      updatePassword();
    } else {
      updateName();
    }
  };

  return props.trigger ? (
    <div className="z-100 fixed w-[100vw] h-[100vh] top-0 left-0 backdrop-blur-sm bg-black/60 popup scroll-none">
      <div
        ref={modalRef}
        className="flex flex-col items-center justify-center popup-iner h-full"
        onClick={closeModal}
      >
        <div className="bg-white xl:w-2/3 s:w-[90vw] h-fit rounded-xl xl:p-12 s:p-8">
          <div className="flex flex-row w-full justify-between">
            <h1 className="flex-grow text-4xl font-bold my-8">
              {showChangePassword ? "Update Password" : "Edit Team Details"}
            </h1>

            <button
              onClick={() => {
                props.onClose();
              }}
              className="text-3xl"
            >
              <i className="bx bx-x"></i>
            </button>
          </div>

          <form onSubmit={handleSubmit}>
            <div
              class={
                "w-full w-[400px] opacity-100 rounded " +
                (showChangePassword ? "hidden" : "block")
              }
            >
              <label className="text-lg font-bold">Team Name</label>
              <input
                type="text"
                onChange={(e) => {
                  setName(e.target.value);
                }}
                name="name"
                placeholder="Team Name"
                value={name}
                className="w-full border h-full my-3 p-4 text-xl rounded"
              />
            </div>

            <div
              class={
                "w-full w-[400px] opacity-100 rounded " +
                (showChangePassword ? "block" : "hidden")
              }
            >
              <label className="text-lg font-bold">Old Password</label>
              <input
                type="password"
                name="oldPassword"
                onChange={(e) => {
                  setOldPassword(e.target.value);
                }}
                placeholder="Old Password"
                value={oldPassword}
                className="w-full h-full my-3 p-4 text-xl rounded border border-slate-200"
              />
            </div>

            <div
              class={
                "w-full w-[400px] opacity-100 rounded " +
                (showChangePassword ? "block" : "hidden")
              }
            >
              <label className="text-lg font-bold">New Password</label>
              <input
                type="password"
                name="newPassword"
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
                placeholder="New Password"
                value={newPassword}
                className="w-full h-full my-3 p-4 text-xl rounded border border-slate-200"
              />
            </div>

            <div className="flex xl:flex-row s:flex-col justify-start w-full items-start">
              <button
                className="w-content bg-white text-gfg-green rounded-2xl mt-8 my-4 w-[300px] flex flex-col justify-center items-center py-4 shadow-md cursor-pointer bold font-bold hover:text-white hover:bg-green-700 hover:scale-110 transition duration-400"
                onClick={(e) => {
                  e.preventDefault();
                  setShowChangePassword(!showChangePassword);
                }}
              >
                {showChangePassword ? "Change Team Name" : "Change Password"}
              </button>
              <button
                type="submit"
                className="w-content bg-gfg-green rounded-2xl xl:mt-8 s:mt-2 my-4 w-[300px] mx-auto flex flex-col justify-center items-center py-4 shadow-md cursor-pointer bold font-bold text-white hover:bg-green-700 hover:scale-110 transition duration-400">
                Update
                {showChangePassword ? " Password" : " Team Name"}
              </button>
            </div>
          </form>
        </div>

        {/* <div className="bg-white w-2/3 h-fit rounded-xl p-6">
          <div className="flex justify-between">
            <h1 className="text-4xl font-bold my-8">Edit Team Details</h1>
          </div>
          <div className="w-full w-[400px] opacity-100 rounded">
            <form onSubmit={handleChangeNameSubmit}>
              <label className="text-2xl font-bold">Team Name</label>

              <input
                type="text"
                onChange={(e) => {
                  setName(e.target.value);
                }}
                name="name"
                placeholder="Team Name"
                value={name}
                className="w-full border h-full my-3 p-4 text-xl rounded"
              />
              <button
                type="submit"
                className="bg-green-700 rounded-xl text-xl px-4 my-4 py-2 text-white">
                Save
              </button>
            </form>
          </div>
        </div> */}
      </div>
    </div>
  ) : (
    ""
  );
};

export default EditTeamPopup;
