import React from 'react';

const CustomModal = ({ isOpen, onClose, title, description, additionalInfo }) => {
  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
          <div className="relative w-auto max-w-3xl mx-auto my-6 ">
            {/*content*/}
            <div onCLick={onClose} className="relative flex flex-col w-full bg-white rounded-xl outline-none focus:outline-none border-solid border-2 border-gray shadow-lg shadow-slate-500/50">
              {/*header*/}
              <div className="flex items-start justify-between p-5 border-b border-solid rounded-t">
                <h3 className="text-3xl font-semibold leading-[50px]">{title}</h3>
              </div>
              {/*body*/}
              <div className="relative p-6 flex-auto">
                <p className="my-2 text-gray-600 text-xl leading-relaxed">Problem Description:</p>
                <p className="my-4 text-gray-600 text-xl leading-relaxed">{description}</p>
                {additionalInfo && <p className="my-4 text-gray-600 text-lg leading-relaxed">{additionalInfo}</p>}
              </div>
              {/*footer*/}
              <div className="flex items-center justify-end p-6 border-t border-solid rounded-b">
                <button
                  className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={onClose}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={isOpen ? 'fixed inset-0 backdrop-blur-sm bg-black/50' : 'hidden'} onClick={onClose}></div>
    </>
  );
};

export default CustomModal;