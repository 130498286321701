import React from "react";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ISLOGGED } from "../../../utils/constants";
import Spline from "@splinetool/react-spline";
import HackathonHeader from "../../../Components/hacakthonHeader";
// import CountdownTimer from "../../../Components/countdownTimer/CountdownTimer";
import HackMatrixFooter from "../../../Components/HackMatrixFooter/HackMatrixFooter";
import FAQ from "../../../Components/Accordian/FAQ";
import TeamMembers from "../../../Components/TeamMembers/TeamMembers";

const HackathonPage = () => {
  const ref = useRef(null);
  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  };

  const navigate = useNavigate();

  const handleScroll = () => {};

  return (
    <>
      <div className="main-container" onScroll={handleScroll}>
        <div className="z-10 absolute w-full">
          <HackathonHeader className="pb-2" />
        </div>

        <div className="stacked-container">
          <Spline
            className={"fixed z-0 mt-10 lg:scale-[1.5] s:scale-[1]"}
            scene="https://prod.spline.design/9dq72NtO2DuBSfQh/scene.splinecode"
          />

          <div className="flex flex-col absolute w-full text-center translate-y-[26vh]">
            <h1 className="text-black xl:text-[6vw] font-bold s:text-[11vw]">
              Hack Matrix 2.0
            </h1>

            <h3 className="text-black xl:text-[1.7vw] ">
              Pune's Most Prestigious Hackathon is back again!{" "}
            </h3>
          </div>

          {/* Countdown Timer */}
          <div className="absolute w-full flex flex-col items-center translate-y-[59vh] timer-container h-fit">
            {/* <CountdownTimer /> */}
            <button
              className="bg-green-600 rounded-2xl mt-12 mb-12 p-12 mx-auto flex flex-col justify-center items-center py-6 shadow-md cursor-pointer bold font-bold text-white text-xl hover:bg-gfg-green hover:scale-105 transition duration-500"
              onClick={() => {navigate("/hackmatrix/finalists")}}>
              View Round-1 Results
            </button>
          </div>

          <div className="scroll-to-bottom-section absolute bg-blue w-full flex flex-col justify-center translate-y-[90vh] w-object">
            <button
              className="flex flex-col items-center cursor-pointer"
              onClick={handleClick}>
              <div className="scroll-to-bottom xl:text-[1.2vw] font-bold sm:text-[2vw] s:text-[5vw]">
                {" "}
                Continue{" "}
              </div>
              <svg
                className="h-fit xl:w-[2vw] text-black-500 s:text-[5vw]"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round">
                {" "}
                <path stroke="none" d="M0 0h24v24H0z" />{" "}
                <polyline points="6 9 12 15 18 9" />
              </svg>
            </button>
          </div>

          <div className="absolute adjustment-container flex flex-col justify-center items-center w-full translate-y-[110vh]">
            <div className="flex flex-col items-center justify-between mx-auto xl:w-[55vw] sm:w-[75vw] s:w-full h-fit bg-white bg-opacity-25 backdrop-blur-sm rounded-xl drop-shadow-xl border border-slate-200">
              <div className="flex flex-col items-center content-container">
                <h1 className="text-black xl:text-5xl mx-8 my-12 font-bold text-center s:text-[9vw]">
                  What is Hack Matrix?
                </h1>

                <h3 className="xl:w-[50%] mx-8 text-center xl:text-xl s:text-[4vw] s:w-[80%]">
                  HackMatrix 2.0 is Pimpri Chinchwad College of Engineering's{" "}
                  <span className="font-bold text-[#2f8d46]">
                    Offline 24 Hour Hackathon.
                  </span>
                  Yes! You heard it right, a 24 hour offline hackathon under
                  ARTIMAS 2.0 with interesting and fun activities, food and
                  entertainment, jam packed with lots of coding and
                  brainstorming. So what are you waiting for? Get ready for a
                  blast with AiMSA and PCCOE's GeeksforGeeks Student Chapter.
                  Register Now for Hack Matrix 2.0!
                </h3>
              </div>

              <div className="action-buttons-container flex xl:flex-row justify-center xl:my-8 lg:gap-x-24 md:gap-x-8 sm:gap-x-2 md:px-0 sm:px-4 items-center sm:items-center s:flex-col s:justify-center">
                <button
                  className={
                    "w-content bg-white text-gfg-green rounded-xl m-12 mx-auto flex flex-col justify-center shadow-md cursor-pointer xs:px-16 lg:px-24 xs:py-4 lg:py-6 font-bold s:mb-4 xl:mt-4 s:mt-8 " +
                    (localStorage.getItem(ISLOGGED) ? "hidden" : "block")
                  }
                  onClick={() =>
                    window.open("https://artimas.pccoeaimsa.org/")
                  }>
                  Register for Hack Matrix 2.0
                </button>

                <button
                  ref={ref}
                  onClick={() => {
                    const loggedin = localStorage.getItem(ISLOGGED);

                    if (loggedin) {
                      navigate("/dashboard", { replace: false });
                    } else {
                      navigate("/login", { replace: false });
                    }
                  }}
                  className="xl:w-fit bg-gfg-green rounded-xl text-center m-12 mx-auto justify-center shadow-md cursor-pointer xs:px-16 lg:px-24 xs:py-4 lg:py-6 bold font-bold text-white s:my-4 s:w-full s:mb-6">
                  {localStorage.getItem(ISLOGGED) ? "Go To Dashboard" : "Login"}
                </button>
              </div>
            </div>

            {/* Numbers Display */}
            <div className="numbers-container absolute w-full mx-auto xl:translate-y-[50vh] s:translate-y-[75vh] flex xl:flex-col justify-center items-center">
              <div className="alignment-container flex xl:flex-row s:flex-col flex-wrap gap-x-8 items-center">
                <div className="flex flex-col items-center gap-y-2">
                  <h1 className="font-bold text-5xl">60+</h1>
                  <h2 className="font-bold">Registered Teams</h2>
                </div>

                <div className="divider xl:w-1 xl:h-24 s:w-full s:h-1 bg-black rounded-xl xl:mx-4 s:my-4" />

                <div className="flex flex-col items-center gap-y-2">
                  <h1 className="font-bold text-5xl">200+</h1>
                  <h2 className="font-bold">Participants</h2>
                </div>

                <div className="divider xl:w-1 xl:h-24 s:w-full s:h-1 bg-black rounded-xl xl:mx-4 s:my-4" />

                <div className="flex flex-col items-center gap-y-2">
                  <h1 className="font-bold text-5xl">1.5+ Lac</h1>
                  <h2 className="font-bold">Worth of prizes and Goodies</h2>
                </div>
              </div>
            </div>
          </div>

          <div className="absolute w-full xl:translate-y-[215vh] xs:translate-y-[255vh]">
            <div className="flex flex-col items-center tracks-container justify-center">
              <h1 className="text-5xl font-bold s:text-center xl:my-12 s:my-16">
                Development Tracks
              </h1>
            </div>
            <div className="tracks-container flex flex-col items-center justify-center">
              <div className="track-names flex flex-wrap md:flex-row sm:flex-col justify-center items-center lg:gap-x-24 md:gap-x-16 px-24 md:px-16 sm:gap-y-16 sm:py-16 s:py-12 s:gap-y-12 bg-white bg-opacity-25 backdrop-blur-sm rounded-xl drop-shadow-xl border border-slate-200">
                <div className="flex flex-col gap-y-6 justify-center items-center lg:w-80 md:w-64 sm:w-60 aspect-square cursor-pointer rounded-full transition duration-500 hover:scale-110">
                  <img
                    className="w-54 ml-4 mr-2"
                    src={require("../../../assets/track_food.jpg")}
                    alt="GFG Logo"
                  />
                  <h1 className="text-2xl font-bold text-center">
                    Food and Healthcare
                  </h1>
                </div>

                <div className="flex flex-col gap-y-6 justify-center items-center lg:w-80 md:w-64 sm:w-60 aspect-square cursor-pointer rounded-full transition duration-500 hover:scale-110">
                  <img
                    className="w-54 ml-4 mr-2"
                    src={require("../../../assets/track_education.jpg")}
                    alt="GFG Logo"
                  />
                  <h1 className="text-2xl font-bold text-center">Education</h1>
                </div>

                <div className="flex flex-col gap-y-6 justify-center items-center lg:w-80 md:w-64 sm:w-60 aspect-square cursor-pointer rounded-full transition duration-500 hover:scale-110">
                  <img
                    className="w-54 ml-4 mr-2"
                    src={require("../../../assets/track_awareness.jpg")}
                    alt="GFG Logo"
                  />
                  <h1 className="text-2xl font-bold text-center">
                    Welfare and Awareness
                  </h1>
                </div>
              </div>
            </div>

            <div className="absolute translate-y-[1vh] w-full h-content">
              {/* Sponsers Section */}
              <div className="sponsers absolute w-full translate-y-[10vh]">
                <div className="sponsers-container flex flex-col items-center justify-center">
                  <h1 className="text-5xl font-bold my-16">Our Sponsors</h1>

                  <div className="flex flex-row flex-wrap justify-between gap-x-12 s:gap-y-12 s:justify-center s:mx-8">
                    <img
                      className="w-[10vw] ml-4 mr-2 aspect-square object-fit"
                      src={require("../../../assets/time.jpg")}
                      alt="Time Institute"
                    />

                    <img
                      className="w-[10vw] ml-4 mr-2 aspect-square object-fit"
                      src={require("../../../assets/tapovan.png")}
                      alt="Tapovan Industries"
                    />

                    <img
                      className="w-[10vw] ml-4 mr-2 aspect-square object-fit"
                      src={require("../../../assets/toppers.png")}
                      alt="Toppers"
                    />

                    <img
                      className="w-[10vw] ml-4 mr-2 aspect-square object-fit"
                      src={require("../../../assets/mechease.png")}
                      alt="MechEase Training Institute"
                    />

                    <img
                      className="w-[10vw] ml-4 mr-2 aspect-square object-fit"
                      src={require("../../../assets/panse.png")}
                      alt="Panse"
                    />

                    <img
                      className="w-[10vw] ml-4 mr-2 aspect-square object-fit"
                      src={require("../../../assets/stylectro.png")}
                      alt="Stylectro"
                    />
                  </div>
                </div>

                <div className="absolute flex flex-col translate-y-[10vh] items-center justify-center w-full">
                  {/* FAQ's */}
                  <div className="block w-full mx-auto h-fit flex flex-col w-full sm:w-[75vw] h-fit rounded-xl bg-white bg-opacity-25 backdrop-blur-sm  drop-shadow-xl">
                    <h3 className="text-5xl font-bold w-fit mx-auto mb-4">
                      FAQ's
                    </h3>
                    <FAQ />
                  </div>
                  {/* Contact Support */}
                  <h3 className="text-5xl font-bold w-fit mx-auto mb-8 xl:mt-24 s:mt-16 s:mx-4">
                    Contact Support
                  </h3>
                  <div className="w-4/5 mx-auto flex flex-col items-center justify-between sm:w-[75vw] h-fit bg-white bg-opacity-25 backdrop-blur-sm rounded-xl drop-shadow-xl border border-slate-200">
                    <div className=" my-8">
                      <TeamMembers />
                    </div>
                  </div>

                  {/* Back to top button */}
                  <div className="block w-full flex flex-col justify-center items-center">
                    <div className="xs:mt-12 w-fit">
                      <button
                        className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded-full shadow xs:text-sm sm:text-lg"
                        onClick={scrollToTop}>
                        Back to Top
                      </button>
                    </div>
                  </div>

                  <div className="w-full h-content">
                    <HackMatrixFooter />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HackathonPage;
