import CustomModal from "../problemStatements/CustomModal";
import { useState } from "react";

function MyAccordion({ title, details, isOpen, toggleAccordion }) {
  return (
    <div className="py-2">
      <button className="flex justify-between w-full" onClick={toggleAccordion}>
        <span className="font-bold text-xl s:text-left">{title}</span>
        {/* {accordionOpen ? <span>-</span> : <span>+</span>} */}
        {/* {isOpen ? <span>-</span> : <span>+</span>} */}
        <svg
          className={`w-[15px] h-[10px] ${
            isOpen ? "rotate-180" : ""
          } transition-all duration-300 ease-in-out`}
          viewBox="0 0 42 25">
          <path
            d="M3 3L21 21L39 3"
            stroke="white"
            strokeWidth={7}
            strokeLinecap="round"
          />
        </svg>
      </button>
      <div
        className={`grid overflow-hidden transition-all duration-300 ease-in-out text-black text-sm ${
          isOpen ? "grid-rows-[1fr] opacity-100" : "grid-rows-[0fr] opacity-0"
        }`}>
        <div className="overflow-hidden p-1 text-lg">{details}</div>
      </div>
    </div>
  );
}

const statementDesc = [
  {
    0: "Develop a user-friendly platform that acts as a centralized hub for students who need information on universities, courses, admission processes, faculty details, alumni experiences, career paths, and employer partnerships. This solution is expected to provide filters, a contact form that will provide direct communication with university representatives, and a bookmarking system for users to save preferred universities and courses. The primary aim is to simplify and enhance the higher education exploration process, reducing the information gap, simplifying the application process, and helping students in their pursuit of academic and career success",

    1: "The solution should help solve the lack of mentorship in education. Many students struggle to find mentors aligned with their goals and interests. Existing systems often lack efficiency and user-friendliness, affecting mentor-student connections. Because there is not any such platform, students cannot get guidance specific to their individual needs. This problem requires the development of a system that allows students to easily input their preferences and provides a smoother process for mentor matching, ensuring personalized, effective mentorship experiences for educational and career growth.",

    2: "Develop a solution to address the challenge of maintaining focus and improving attention spans within educational environments. Develop a solution that uses interactive methods, or other creative approaches to improve the learning experience and allow continuous concentration among students. Create an environment that helps make education effective and keeps the knowledge for a longer time.",

    3: "Develop a  platform for college clubs and communities to manage their organizations and events, recruit new volunteers, and engage with other communities. The platform should offer a seamless way for communities to discover and connect with like-minded volunteers by finding organizations or their interests. Additionally, it should allow volunteers to explore various communities and connect with their preferred community.",
  },
  {
    0: "A lot of people face barriers such as societal stigma, limited resources, and insufficient awareness when fighting against mental health challenges. This causes everyday stress which further complicates the situation. The existing support systems are often divided and may not adequately address the population's diverse needs. The problem statement expects a solution that can help users overcome social barriers to access by providing effective support and resources for individuals facing these issues. Incorporating a chat functionality into the solution would enhance user engagement and overall effectiveness in promoting mental health and well-being.",

    1: " In today's fast-paced lifestyle people are struggling to manage their daily nutritional intake, which is causing adverse effects on their overall health and well-being. Many people face challenges in maintaining a balanced diet due to limited time, and insufficient nutritional awareness. It is also difficult to understand the complex dietary information. All of this contributes to a range of health issues, including nutritional deficiencies, weight management problems, and a higher risk of chronic diseases. There is a need for a solution that allows people to easily track their nutritional intake and receive personalized recommendations, improving their health and avoiding the adverse effects associated with inadequate dietary habits.",

    2: "As the healthcare industry increasingly relies on digital platforms for storing patient information it is important to store the patient data securely. The solution should be able to store health information such that unauthorized access and manipulation is not possible. The integrity of patient data should be maintained to help increase confidence in the privacy of health records.",

    3: "A huge amount of edible food is wasted in hotels and restaurants, contributing to environmental issues and resource wastage. The problem statement expects a system that allows organized management and redistribution of excess food to those in need. This would help address social and environmental concerns by distributing excess resources towards sustainable and impactful initiatives. The goal is to establish an effective, scalable framework that encourages optimal food management and contributes to a more sustainable food ecosystem.",
  },
  {
    0: "Private transportation methods in urban cities are a major reason for traffic congestion and environmental pollution. The solution should encourage shared transportation options, such as carpooling, ride-sharing, and public transportation to reduce the impact of private transport vehicles. The solution can provide incentives for preferring public transport over private transport.",

    1: "Natural disasters are sudden and unknown due to which there is a lack of awareness and preparedness often increases their impact. The problem statement expects a solution that not only improves public awareness about various types of natural disasters but also provides approved emergency procedures and readily available emergency contacts to ensure swift and effective responses in times of crisis.",

    2: "Develop a solution that improves users’s awareness regarding money savings. The solution can contain dashboards for easy understanding of their user’s spending and savings. The solution should allow users to create and manage categories for their expenses (food, health, entertainment, etc.) and provide visualization of this data.",

    3: "Communities worldwide are increasingly recognizing the importance of environmental supervision, and organizing local clean-up events plays a crucial role in fostering a cleaner and healthier environment. However, there is a need for a user-friendly platform that streamlines the process of planning and tracking these events while engaging volunteers effectively. The solution should include an intuitive and easy-to-use platform dedicated to community clean-up initiatives. This platform aims to empower communities to seamlessly plan and coordinate local clean-up events, simplifying the organization process for both event organizers and volunteers.",
  },
];

function PSAccordion({
  accordionIndex,
  name,
  statements,
  isOpen,
  toggleAccordion,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStatement, setSelectedStatement] = useState(null);
  const [statementIndex, setStatementIndex] = useState(0);

  const toggleModal = (statement) => {
    setSelectedStatement(statement);
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <div className="my-3 border-solid border-2 rounded-xl border-slate-200">
        <button
          className="flex justify-between w-full py-7"
          onClick={toggleAccordion}>
          <div className="font-bold text-[34px] w-full text-left pl-10">
            {name}
          </div>
          <svg
            className={`w-[30px] h-[25px] ${
              isOpen ? "rotate-45" : ""
            } transition-all duration-300 ease-in-out m-2`}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12 5V19"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5 12H19"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"></svg>
        </button>
        <div
          className={`grid overflow-hidden transition-all duration-300 ease-in-out text-black text-sm ${
            isOpen
              ? " grid-rows-[auto] opacity-100"
              : "grid-rows-[0] opacity-0 hidden"
          }`}>
          {statements.map((statement, index) => (
            <>
              <div
                className="flex flex-row justify-between items-center cursor-pointer hover:bg-slate-200 hover:scale-105 transition duration-500"
                onClick={() => {
                  toggleModal(statement);
                  setStatementIndex(index);
                }}>
                <div
                  key={index}
                  className=" overflow-hidden p-10 text-2xl rounded-xl">
                  {statement}
                </div>
                <i class="bx bx-chevron-right text-[2vw] mr-8"></i>
              </div>
              <hr />
            </>
          ))}
          {/* {statements} */}
        </div>
      </div>

      <CustomModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={selectedStatement}
        description={statementDesc[accordionIndex][statementIndex]}
        additionalInfo=""
      />
    </>
  );
}

export default MyAccordion;

export { PSAccordion };
