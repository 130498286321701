import React from "react";
import gautam from "../../assets/Team/gautam.JPG";
import powalkar from "../../assets/Team/powalkar.JPG";
import pranav from "../../assets/Team/pranav.JPG";
import yash from "../../assets/Team/yash.JPG";
import utkarsh from "../../assets/Team/utkarsh.JPG";
import kaustubh from "../../assets/Team/kaustubh.JPG";

function TeamMembers() {
  return (
    <>
      <div className="flex flex-wrap justify-center xl:w-[70%] s:w-full xl:mx-auto">
        <div className="bg-slate-200 w-60 text-center rounded-md m-3 hover:scale-115 transition duration-500 ease-in-out hover:translate-y-[-10px] cursor-pointer">
          <div className="h-[240px]">
            <img
              src={gautam}
              alt=""
              className="object-cover object-top w-60 h-56"
            />
          </div>
          <div className="text-xl font-bold">Gautam Bhagat</div>
          <div>President</div>
          <div className="mt-2 mb-4 underline">+91 9970102190</div>
        </div>
        <div className="bg-slate-200 w-60 text-center rounded-md m-3 hover:scale-115 transition duration-500 ease-in-out hover:translate-y-[-10px] cursor-pointer">
          <div className="h-[240px]">
            <img
              src={powalkar}
              alt=""
              className="object-cover object-top w-60 h-56"
            />
          </div>
          <div className="text-xl font-bold">Atharv Powalkar</div>
          <div>Vice-President</div>
          <div className="mt-2 mb-4 underline">+91 9284674008</div>
        </div>
        <div className="bg-slate-200 w-60 text-center rounded-md m-3 hover:scale-115 transition duration-500 ease-in-out hover:translate-y-[-10px] cursor-pointer">
          <div className="h-[240px]">
            <img
              src={pranav}
              alt=""
              className="object-cover object-top w-60 h-56"
            />
          </div>
          <div className="text-xl font-bold">Pranav Kale</div>
          <div>Technical Head</div>
          <div className="mt-2 mb-4 underline">+91 7756956788</div>
        </div>
        <div className="bg-slate-200 w-60 text-center rounded-md m-3 hover:scale-115 transition duration-500 ease-in-out hover:translate-y-[-10px] cursor-pointer">
          <div className="h-[240px]">
            <img
              src={yash}
              alt=""
              className="object-cover object-top w-60 h-56"
            />
          </div>
          <div className="text-xl font-bold">Yash Sonar</div>
          <div>Co-Technical Head</div>
          <div className="mt-2 mb-4 underline">+91 8390671652</div>
        </div>
        <div className="bg-slate-200 w-60 text-center rounded-md m-3 hover:scale-115 transition duration-500 ease-in-out hover:translate-y-[-10px] cursor-pointer">
          <div className="h-[240px]">
            <img
              src={utkarsh}
              alt=""
              className="object-cover object-top w-60 h-56"
            />
          </div>
          <div className="text-xl font-bold">Utkarsh Patil</div>
          <div>Co-Marketing Head</div>
          <div className="mt-2 mb-4 underline">+91 7020391355</div>
        </div>
        <div className="bg-slate-200 w-60 text-center rounded-md m-3 hover:scale-115 transition duration-500 ease-in-out hover:translate-y-[-10px] cursor-pointer">
          <div className="h-[240px]">
            <img
              src={kaustubh}
              alt=""
              className="object-cover object-top w-60 h-56"
            />
          </div>
          <div className="text-xl font-bold">Koustubh Kulkarni</div>
          <div>Co-Events Head</div>
          <div className="mt-2 mb-4 underline">+91 9307058374</div>
        </div>
      </div>
    </>
  );
}

export default TeamMembers;
