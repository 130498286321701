import React, { useState, useEffect } from "react";

const Typewriter = ({ texts, delay, infinite = false, backspeed = delay }) => {
  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [clear, setClear] = useState(false);
  const [arrayIndex, setArrayIndex] = useState(0);
  const [blink, setBlink] = useState(false);
  const [cursor, setCursor] = useState("_");

  useEffect(() => {
    let timeout;

    const text = texts[arrayIndex];
    setBlink(currentIndex === text.length);

    if (currentIndex < text.length && !clear) {
      timeout = setTimeout(() => {
        setCurrentText((prevText) => prevText + text[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, delay);
    } else if (infinite) {
      if (currentIndex === 0) {
        // updating the array index and resetting the states
        setArrayIndex(arrayIndex === texts.length - 1 ? 0 : arrayIndex + 1);
        setClear(false);
        setCurrentText("");
      } else {
        if (!clear) {
          setClear(true);
        }

        // reversing animation
        timeout = setTimeout(
          () => {
            setCurrentText((prevText) =>
              prevText.substring(0, prevText.length - 2)
            );
            setCurrentIndex((prevIndex) => prevIndex - 1);

            // also setting the blink prop
          },
          currentIndex === text.length ? 2000 : backspeed
        );
      }
    }

    return () => clearTimeout(timeout);
  }, [currentIndex, delay, clear, arrayIndex, backspeed, infinite, texts]);

  useEffect(() => {
    // console.log(blink);
    const timeout = setTimeout(() => {
      if (blink) {
        setCursor(cursor === "" ? "_" : "");
      }
      else {
        if( cursor === "" ) {
          setCursor("_")
        }
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [cursor, blink]);

  return (
    <span>
      {currentText}
      <span className="text-gfg-green">{cursor}</span>
    </span>
  );
};

export default Typewriter;
