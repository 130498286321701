import image2 from "../../assets/figma2.gif";
import image3 from "../../assets/figma3.gif";
import image4 from "../../assets/figma4.gif";
import image5 from "../../assets/figma5.gif";
import image6 from "../../assets/figma6.gif";
import FadeInSection from '../../utils/fade_in_section'

import "../../index.css";
import "./About.css";

import Header from "../../Components/Header/Header.js";
import Footer from "../../Components/Footer/Footer.js";


const AboutPage = () => {
  const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth", // for smoothly scrolling
      });
  };

  return (
    <>
      <Header/>
      <div className="flex xs:mt-12 lg:mt-47 flex-col items-center">
        {/* container containing all the aboslute divs */}
        <div className="absolute absolute-container">
          {/* background gradient circles */}
          <div
            className="absolute -left-32 -top-48 w-[691.78px] h-[660.39px] origin-top-left rounded-full blur-[650px] -z-10 xs:hidden lg:block"
          />
          {/* end of background gradient circles */}
        </div>

        <div className="flex gap-x-16 justify-center lg:flex-row xs:flex-col lg:m-12 xs:m-0">
          <div className="flex flex-col h-fit xs:w-full lg:w-2/3 xs:p-4">
            {/* Vision and Mission Section */}
            <FadeInSection>
              <div className="bg-white p-8 rounded-xl shadow-xl text-center">
                <h1 className="text-4xl font-semibold mb-6">Our Vision</h1>
                <p className="text-gray-600 mb-6 mx-4">
                  To be the leading source for learning and practicing computer
                  science concepts.
                </p>
                <div className="h-px bg-gray-300 my-10"></div>
                <h1 className="text-4xl font-semibold mb-6">Our Mission</h1>
                <p className="text-gray-600 mb-6 mx-4">
                  Empower geeks worldwide to excel in their technical skills
                  through quality content and an engaged community.
                </p>
              </div>
            </FadeInSection>
          </div>

          <div className="">
            <FadeInSection>
              {/* Another Centered Tagline */}
              <div className="our-domian flex justify-center xs:mt-24 lg:mt-0 pb-8">
                <p className="font-bold text-4xl">OUR DOMAINS</p>
              </div>

              <div className="flex justify-center py-5 mx-4">
                <div className="imgcontainer py-[3rem] xs:py-[2rem] overflow-hidden relative shadow-lg mx-auto px-8 lg:flex lg:items-center lg:space-x-8 xs:max-w-[100%] lg:max-w-10xl xs:h-fit lg:h-[fit-content]">
                  <div className="bgrad absolute inset-0">
                    <svg
                      className="static"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 476 300"
                      fill="none">
                      <g filter="url(#filter0_f_38_115)">
                        <path
                          d="M239 54.5C239 128.23 164.008 188 71.5 188C-21.0077 188 -96 128.23 -96 54.5C-96 -19.23 -21.0077 -79 71.5 -79C164.008 -79 239 -19.23 239 54.5Z"
                          // fill="#B25656"
                        />
                      </g>
                      <defs>
                        <filter
                          id="filter0_f_38_115"
                          x="-333"
                          y="-316"
                          width="809"
                          height="741"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB">
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="BackgroundImageFix"
                            result="shape"
                          />
                          <feGaussianBlur
                            stdDeviation="118.5"
                            result="effect1_foregroundBlur_38_115"
                          />
                        </filter>
                      </defs>
                    </svg>
                  </div>
                  <div className="text-area relative py-12 lg:flex-1">
                    <h2 className="tp1 py-5">Programming & Coding Competition</h2>
                    <div className="text-a1">
                      <p className="tp2">
                        Focusing on enhancing coding skills and organizing
                        coding competitions, hackathons, and programming
                        contests to foster a competitive coding culture among
                        students.
                      </p>
                    </div>
                  </div>

                  <div className="about-logo-section flex justify-end items-center lg:w-1/3 xs:mt-4 lg:mt-0">
                    <img className="programming-code" src={image2} />
                  </div>
                </div>
              </div>
            </FadeInSection>

            <FadeInSection>
              <div className="flex justify-center py-5 mx-4">
                <div className="imgcontainer py-[3rem] overflow-hidden relative shadow-lg mx-auto px-8 lg:flex lg:items-center lg:space-x-8 xs:max-w-[100%] lg:max-w-10xl xs:h-fit lg:h-[fit-content]">
                  <div className="about-logo-section  flex lg:w-full">
                    <img className="programming-code" src={image3} />
                  </div>

                  <div className="bgradi absolute inset-0 ">
                    <svg
                    width=""
                    height="100%"
                    className="aspect-square"
                      viewBox="0 0 669 300"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <g filter="url(#filter0_f_42_177)">
                        <ellipse
                          cx="668.5"
                          cy="268"
                          rx="200.5"
                          ry="149"
                          // fill="#33A4D5"
                          fill-opacity="0.97"
                        />
                      </g>
                      <defs>
                        <filter
                          id="filter0_f_42_177"
                          x="0"
                          y="-349"
                          width="1337"
                          height="1234"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB">
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="BackgroundImageFix"
                            result="shape"
                          />
                          <feGaussianBlur
                            stdDeviation="234"
                            result="effect1_foregroundBlur_42_177"
                          />
                        </filter>
                      </defs>
                    </svg>
                  </div>

                  <div className="text-area relative xs:py-0 lg:py-10 lg:w-4/2 pl-4 lg:pl-0 xs:pt-0 lg:pt-1 pr-4">
                    <h2 className="tp1 py-5">TECHNICAL WORKSHOPS & TRAINING</h2>
                    <div className="text-a1">
                      <p className="tp2">
                        Conducting workshops, training sessions, and hands-on
                        tutorials on various technical topics like data
                        structures, algorithms, web development, app
                        development, machine learning, artificial intelligence,
                        etc., to provide practical learning experiences.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </FadeInSection>

            <FadeInSection>
              {/* Another Centered Tagline */}
              <div className="flex justify-center py-5 mx-4">
                <div className="imgcontainer py-[3rem] overflow-hidden relative shadow-lg mx-auto px-8 lg:flex lg:items-center lg:space-x-8 xs:max-w-[100%] lg:max-w-10xl xs:h-fit lg:h-[fit-content]">
                  <div className="bgrad absolute inset-0">
                    <svg
                      className="static"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 476 300"
                      fill="none">
                      <g filter="url(#filter0_f_43_195)">
                        <path
                          d="M239 54.5C239 128.23 164.008 188 71.5 188C-21.0077 188 -96 128.23 -96 54.5C-96 -19.23 -21.0077 -79 71.5 -79C164.008 -79 239 -19.23 239 54.5Z"
                          // fill="#22FF56"
                          fill-opacity="0.4"
                        />
                      </g>
                      <defs>
                        <filter
                          id="filter0_f_43_195"
                          x="-333"
                          y="-316"
                          width="809"
                          height="741"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB">
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="BackgroundImageFix"
                            result="shape"
                          />
                          <feGaussianBlur
                            stdDeviation="118.5"
                            result="effect1_foregroundBlur_43_195"
                          />
                        </filter>
                      </defs>
                    </svg>
                  </div>
                  <div className="text-area relative py-10 lg:w-4/2 pl-4 lg:pl-0 pt-1 pr-4">
                    <h2 className="tp1 py-5">
                      Industry Interaction and Guest Lectures
                    </h2>
                    <div className="text-a1">
                      <p className="tp2 text-md">
                        Inviting industry professionals, experts, and guest
                        speakers to deliver talks, seminars, and interactive
                        sessions on emerging technologies, career guidance,
                        industry trends, and real-world applications.
                      </p>
                    </div>
                  </div>

                  <div className="about-logo-section flex justify-end items-center lg:w-full py-6">
                    <img className="programming-code aspect-square" src={image4} />
                  </div>
                </div>
              </div>
            </FadeInSection>

            <FadeInSection>
              <div className="flex justify-center py-5 mx-4">
                <div className="imgcontainer py-[3rem] overflow-hidden relative shadow-lg mx-auto px-8 lg:flex lg:items-center lg:space-x-8 xs:max-w-[100%] lg:max-w-10xl xs:h-fit lg:h-[fit-content]">
                  <div className="about-logo-section flex lg:w-full">
                    <img className="programming-code aspect-square" src={image5} />
                  </div>

                  <div className="bgradi absolute inset-0 ">
                    <svg
                    width=""
                    height="100%"
                    className="aspect-square"
                      viewBox="0 0 669 300"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <g filter="url(#filter0_f_42_177)">
                        <ellipse
                          cx="668.5"
                          cy="268"
                          rx="200.5"
                          ry="149"
                          // fill="#33A4D5"
                          fill-opacity="0.97"
                        />
                      </g>
                      <defs>
                        <filter
                          id="filter0_f_42_177"
                          x="0"
                          y="-349"
                          width="1337"
                          height="1234"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB">
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="BackgroundImageFix"
                            result="shape"
                          />
                          <feGaussianBlur
                            stdDeviation="234"
                            result="effect1_foregroundBlur_42_177"
                          />
                        </filter>
                      </defs>
                    </svg>
                  </div>

                  <div className="text-area relative py-10 lg:w-4/2 pl-4 lg:pl-0 pt-1 pr-4">
                    <h2 className="tp1">
                      Technical Outreach and Community Engagement
                    </h2>
                    <div className="text-a1">
                      <p className="tp2">
                        Engaging with the larger community through initiatives
                        like organizing coding workshops for school students,
                        conducting tech awareness sessions, participating in
                        community-driven projects, and promoting the importance
                        of technology in society.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </FadeInSection>

            <FadeInSection>
              {/* Another Centered Tagline */}
              <div className="flex justify-center py-5 mx-4">
                <div className="imgcontainer py-[3rem] overflow-hidden relative shadow-lg mx-auto px-8 lg:flex lg:items-center lg:space-x-8 xs:max-w-[100%] lg:max-w-10xl xs:h-fit lg:h-[fit-content]">
                  <div className="bgrad absolute inset-0">
                    <svg
                      className="static aspect-square"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 476 300"
                      fill="none">
                      <g filter="url(#filter0_f_38_115)">
                        <path
                          d="M239 54.5C239 128.23 164.008 188 71.5 188C-21.0077 188 -96 128.23 -96 54.5C-96 -19.23 -21.0077 -79 71.5 -79C164.008 -79 239 -19.23 239 54.5Z"
                          // fill="#B25656"
                        />
                      </g>
                      <defs>
                        <filter
                          id="filter0_f_38_115"
                          x="-333"
                          y="-316"
                          width="809"
                          height="741"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB">
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="BackgroundImageFix"
                            result="shape"
                          />
                          <feGaussianBlur
                            stdDeviation="118.5"
                            result="effect1_foregroundBlur_38_115"
                          />
                        </filter>
                      </defs>
                    </svg>
                  </div>
                  <div className="text-area relative py-12 lg:flex-1">
                    <h2 className="tp1 py-5">
                      Career Guidance and Placement Assistance
                    </h2>
                    <div className="text-a1">
                      <p className="tp2">
                        Providing guidance, mentorship, and support to students
                        in their career development, helping them prepare for
                        technical interviews, resume building, and connecting
                        them with internship and job opportunities
                      </p>
                    </div>
                  </div>

                  <div className="about-logo-section flex justify-end items-center lg:w-1/2">
                    <img className="programming-code" src={image6} />
                  </div>
                </div>
              </div>
            </FadeInSection>
          </div>
        </div>

        {/* Back to top button */}
        <div className="flex flex-row justify-center my-24">
          <button
            className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded-full shadow"
            onClick={scrollToTop}>
            Back to Top
          </button>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default AboutPage;
