import React from "react";
import { useState, useEffect } from "react";
import calculateTimeDifference from "../../utils/timeDifference";

const Timeline = () => {
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeDifference(new Date(), new Date("2024-02-19T14:00:00")) / 1000);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimeRemaining( Math.floor( calculateTimeDifference(new Date(), new Date("2024-02-19T14:00:00")) / 1000 ) );
      console.log( timeRemaining );
    }, 1000);

    // Cleanup the interval when the component unmounts
    return () => clearInterval(timerInterval);
  }, []); // The empty dependency array ensures the effect runs only once on mount


  const timeline = [
    {
      id: "0",
      name: "Announcement of Problem Statements",
      status: ( timeRemaining < 172800 ?  true: false ),
      date: '17 Feb 11:00am',
      details: "Problem Statements for Respective tracks would be announced",
    },
    {
      id: "1",
      name: "Commencement of Online Round-1",
      status: ( timeRemaining < 172800 ?  true: false ),
      date: "17 Feb 11:00am",
      details: "Participants can choose a problem statement of their choice and start Development.",
    },
    {
      id: "2",
      name: "Commencement of Submissions for Online Round-1",
      status:  ( timeRemaining < 162000 ?  true: false ),
      date: "17 Feb 2:00pm",
      details: "Submissions will be open for participants to submit their work",
    },
    {
      id: "3",
      name: "Conclusion of Submissions for Online Round-1",
      status: ( timeRemaining <= 86400 ?  true: false ),
      date: "18 Feb 02:00pm",
      details: "Submission will automatically close. No projects will be accepted after the time is over.",
    },
    {
      id: "4",
      name: "Announcement of Qualifying teams for Round-2",
      status: (  false ),
      date: "",
      details:"Teams that qualify for the next round would be announced on the website!",
    },
  ];

  const colors = [
    "bg-green-400",
    "bg-green-500",
    "bg-green-600",
    "bg-green-700",
    "bg-green-800",
  ];

  return (
    <>
      {timeline.map(({ id, name, status, date, details }) => {
        return (
          <div className="flex flex-row items-stretch">
            <div
              className={
                "flex flex-col items-center " +
                (id == "1" ? "justify-end" : "justify-center")
              }>
              <div
                className={
                  "w-1 flex-grow bg-black rounded-xl my-1 " +
                  (id == "0" ? "bg-transparent" : "")
                }></div>

              <div
                className={
                  " h-12 aspect-square rounded-full border " +
                  (status
                    ? colors[id] + " border-black"
                    : " bg-white border-emerald-500")
                }></div>

              <div
                className={
                  "w-1 flex-grow bg-black rounded-xl my-1 " +
                  (id == "4" ? "bg-transparent" : "")
                }></div>
            </div>

            <div className="flex flex-col ml-4 p-4 h-fit w-full rounded-xl border border-slate-200 my-4">
              <h1 className="text-xl font-bold">{name}</h1>
              <h1 className="text-sm text-zinc-800">{date}</h1>
              <h2 className="text-md mt-2">{details}</h2>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default Timeline;
