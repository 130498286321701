import HackMatrixFooter from "../../../Components/HackMatrixFooter/HackMatrixFooter";
import Team from "../../../Components/HackMatrixTeam/Team";
import TeamInfoBar from "../../../Components/TeamInfoBar/TeamInfoBar";
import ContactSupportPage from "../../../Components/contactSupport";
import HackathonHeader from "../../../Components/hacakthonHeader";
import ProblemStatementsPage from "../../../Components/problemStatements";
import SubmissionIndex from "../../../Components/submissions";
import Dashboard from "./dashboardPage";
import "./dashboardindex.css";

import React, { useState } from "react";

function DashBoardIndex() {
  const [activeTab, setActiveTab] = useState(1);
  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };
  return (
    <div className="">
      <HackathonHeader />
      <div className="team-info-container w-11/12 mx-auto">
        <TeamInfoBar />
      </div>

      <div className="mx-auto xl:w-11/12 s:w-full bg-white border p-8 mt-10 rounded-2xl shadow-lg">
        <div className="flex flex-row items-center ">
          <i className="bx bx-chevron-left xl:hidden s:block text-[6vw]"></i>

          <div className="flex items-center gap-x-4 around xl:mb-10 s:mb-2 xl:overflow-clip md:overflow-clip s:overflow-scroll">
            <div
              className={`tab cursor-pointer text-center xl:text-[1.5vw] md:text-[2vw] s:text-[5vw] ${
                activeTab === 1
                  ? "active-tab border-b-4 pb-2 border-b-[#2f8d46] decoration-4"
                  : ""
              } px-4 pt-2 mx-2`}
              onClick={() => handleTabClick(1)}>
              Dashboard
            </div>
            <div
              className={`tab cursor-pointer text-center xl:text-[1.5vw] md:text-[2vw] s:text-[5vw] ${
                activeTab === 2
                  ? "active-tab border-b-4 pb-2 border-b-[#2f8d46] decoration-4"
                  : ""
              } px-4 pt-2 mx-2`}
              onClick={() => handleTabClick(2)}>
              Team Members
            </div>
            <div
              className={`tab cursor-pointer text-center xl:text-[1.5vw] md:text-[2vw] s:text-[5vw] ${
                activeTab === 3
                  ? "active-tab border-b-4 pb-2 border-b-[#2f8d46] decoration-4"
                  : ""
              } px-4 pt-2 mx-2`}
              onClick={() => handleTabClick(3)}>
              Problem Statements
            </div>

            <div
              className={`tab cursor-pointer text-center xl:text-[1.5vw] md:text-[2vw] s:text-[5vw] ${
                activeTab === 4
                  ? "active-tab border-b-4 pb-2 border-b-[#2f8d46] decoration-4"
                  : ""
              } px-4 pt-2 mx-2`}
              onClick={() => handleTabClick(4)}>
              Submission
            </div>

            <div
              className={`tab cursor-pointer text-center xl:text-[1.5vw] md:text-[2vw] s:text-[5vw] ${
                activeTab === 5
                  ? "active-tab border-b-4 pb-2 border-b-[#2f8d46] decoration-4"
                  : ""
              } px-4 pt-2 mx-2`}
              onClick={() => handleTabClick(5)}>
              Contact Support
            </div>
          </div>

          <i className="bx bx-chevron-right xl:hidden s:block text-[6vw]"></i>
        </div>

        {/* Content for each tab */}
        <div className="tab-content mt-4 mb-6">
          {activeTab === 1 && <Dashboard />}
          {activeTab === 2 && <Team />}
          {activeTab === 3 && <ProblemStatementsPage />}
          {activeTab === 4 && <SubmissionIndex />}
          {activeTab === 5 && <ContactSupportPage />}
        </div>
      </div>
      <h2 className="mx-auto w-11/12 text-xl my-12 mx-4">
        In case of any issues/mistakes you can contact us for assistance!
      </h2>
      <HackMatrixFooter />
    </div>
  );
}

export default DashBoardIndex;

// export default Dashboard;
