import React from "react";
import { PSAccordion } from "../Accordian/MyAccordion";
import { useState } from "react";
import calculateTimeDifference from "../../utils/timeDifference";

const ProblemStatementsPage = () => {
  const [timeRemaining, setTimeRemaining] = useState(
    calculateTimeDifference(new Date(), new Date("2024-02-17T11:00:00")) / 1000
  );
  const tracks = [
    {
      name: "Education & Technology",
      problemStatements: [
        "Develop a Solution for improving Higher Education Exploration with an Integrated Information Platform.",
        "Develop a streamlined platform for addressing the lack of personalized mentorship using efficient mentor matching, and ensuring tailored and effective mentorship experiences.",
        "Design a solution to improve the focus of students and enhance attention span while studying.",
        "Develop a platform for efficient college community management, volunteering Recruitment, and Meaningful Connections.",
      ],
    },
    {
      name: "Food and Health Care",
      problemStatements: [
        "Develop a solution that can be used to promote mental health and well-being, providing support and resources for individuals facing mental health challenges.",
        "Develop a solution that helps individuals track their daily nutritional intake and provides personalized recommendations for a healthier lifestyle.",
        "Develop a solution to safeguard the security and privacy of Electronic Health Records (EHRs) to keep sensitive patient information safe.",
        "Develop a solution to effectively manage and redistribute excess or unused food from hotels and restaurants, contributing to sustainable practice.",
      ],
    },
    {
      name: "Welfare and Awareness",
      problemStatements: [
        "Develop a solution to reduce the usage of private transportation methods and increase the use of shared mobility.",
        "Develop a solution that improves awareness and preparedness for natural disasters, providing approved emergency procedures for disasters, and emergency contacts.",
        "Develop a solution to improve financial awareness among people, using visualizations, and providing methods to increase savings.",
        "Create an easy-to-use platform that helps communities plan and track local clean-up events, allowing volunteers to sign up, log collected waste, and visualize the impact, fostering a cleaner environment.",
      ],
    },
  ];

  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const downloadPS = () => {
    let url = "http://tinyurl.com/mtrt927p";
    window.open(url, "_blank");
  };

  return (
    <>
      <div className="rounded-xl border xl:p-10 s:p-2">
        {timeRemaining > 0 ? (
          <div>
            <h1 className="text-4xl font-bold mb-6 xl:text-left s:text-center">
              Hackathon Tracks
            </h1>
            <h1 className="text-red-600 xl:text-[1.4vw] s:text-[5vw] text-center pb-5 my-16">
              <i>
                ** Problem Statements will be released on 17th Feb, 2024 at
                11:00 AM **
              </i>
            </h1>

            <div className="w-full mx-auto flex lg:flex-row md:flex-col s:flex-col xl:my-0 s:my-8 justify-evenly items-center">
              <div>
                <div className="flex flex-col items-center justify-center lg:w-[17vw] md:w-[30vw] aspect-square border shadow-lg rounded-full overflow-hidden hover:scale-110 transition duration-500 cursor-pointer">
                  <img
                    className="w-fit px-12"
                    src={require("../../assets/track_food.jpg")}
                    alt="GFG Logo"
                    style={{ objectFit: "cover" }}
                  />
                </div>
                <h1 className="text-2xl mt-4 text-center font-bold mb-6 xl:text-[1.8vw] s:text-[5vw]">
                  Food & Healthcare
                </h1>
              </div>

              <div className="">
                <div className="flex flex-col items-center justify-center lg:w-[17vw] md:w-[30vw] aspect-square border shadow-lg rounded-full overflow-hidden hover:scale-110 transition duration-500 cursor-pointer">
                  <img
                    className="w-fit px-12"
                    src={require("../../assets/track_education.jpg")}
                    alt="GFG Logo"
                    style={{ objectFit: "fill" }}
                  />
                </div>
                <h1 className="text-2xl mt-4 text-center font-bold mb-6 xl:text-[1.8vw] s:text-[5vw]">
                  Education
                </h1>
              </div>

              <div>
                <div className="flex flex-col items-center justify-center lg:w-[17vw] md:w-[30vw] aspect-square border shadow-lg rounded-full overflow-hidden hover:scale-110 transition duration-500 cursor-pointer">
                  <img
                    className="w-fit px-12"
                    src={require("../../assets/track_awareness.jpg")}
                    alt="GFG Logo"
                    style={{ objectFit: "fill" }}
                  />
                </div>
                <h1 className="text-2xl mt-4 text-center font-bold mb-6 xl:text-[1.8vw] s:text-[5vw]">
                  Social Awareness
                </h1>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="flex flex-row justify-between items-center mr-6">
              <h1 className="text-4xl font-bold xl:my-6 s:mb-6">Hackathon Tracks</h1>
              <button onClick={downloadPS} className="xl:w-fit bg-gfg-green rounded-xl text-center cursor-pointer px-16 py-4 bold font-bold text-white s:w-full">Download PS</button>
            </div>

            <div className="w-full mx-auto">
              <div>
                <div className="xl:p-4 s:p-0 h-fit xl:w-full s:w-full s:mx-0">
                  {tracks.map((statements, index) => (
                    <PSAccordion
                      key={index}
                      name={statements.name}
                      statements={statements.problemStatements}
                      isOpen={openIndex === index}
                      accordionIndex={index}
                      toggleAccordion={() => toggleAccordion(index)}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ProblemStatementsPage;
