import React from "react";
import { PAYLOAD } from "../../utils/constants";

function Team() {

  const teamMembers = JSON.parse(localStorage.getItem(PAYLOAD))["members"];
  // setHackTeam(teamMembers)

  return (
    <>
      <h2 className="text-3xl font-bold mb-4 mt-8 mx-8">Team Members</h2>
      <div className="block overflow-auto shadow-md sm:rounded-lg">
        <table className="block z-0 w-full text-left rtl:text-right text-gray-500 px-10 py-4 border-separate border-spacing-y-3">
          <thead className="p-3 m-2 text-black">
            <tr className="text-xl">
              <th className="p-3 m-2 bg-gray-50">S.No.</th>
              <th className="p-3 m-2 bg-gray-50">NAME</th>
              <th className="p-3 m-2 bg-gray-50">EMAIL</th>
              <th className="p-3 m-2 bg-gray-50">COLLEGE</th>
              <th className="p-3 m-2 bg-gray-50">GITHUB</th>
              <th className="p-3 m-2 bg-gray-50">PHONE</th>
              <th className="p-3 m-2 bg-gray-50">ROLE</th>
            </tr>
          </thead>
          <tbody>
            {teamMembers.map((member, index) => (
              <tr className="border-b my-2 border-gray-200 text-xl">
                <td className="p-3 m-2 text-center font-medium text-black">
                  {index + 1}
                </td>
                <td className="p-3 m-2 text-black">
                  {member.participant.firstName +
                    " " +
                    member.participant.lastName}
                </td>
                <td className="p-3 m-2 text-black ">
                  {member.participant.email}
                </td>
                <td className="p-3 m-2 text-black">
                  {member.participant.college}
                </td>
                <td className="p-3 m-2 text-black">
                  {member.participant.github}
                </td>
                <td className="p-3 m-2 text-black">
                  {member.participant.phone}
                </td>
                <td className="p-3 m-2 text-black">{member.role}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default Team;
