import HackathonHeader from "../../../Components/hacakthonHeader";
import HackMatrixFooter from "../../../Components/HackMatrixFooter/HackMatrixFooter";
import React, { useState, useEffect } from "react";
import Spline from "@splinetool/react-spline";
import {
  BACKEND_URL,
  ISLOGGED,
  HACKTEAMTOKEN,
  PAYLOAD,
  SUBMISSION,
} from "../../../utils/constants";

const LoginPage = () => {
  const [loginCred, setLoginCred] = useState({
    teamid: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginCred({ ...loginCred, [name]: value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    let response = await fetch(`${BACKEND_URL}/api/hack/login`, {
      method: "POST",
      body: JSON.stringify(loginCred),
      headers: {
        "Content-Type": "application/json",
      },
    });

    response = await response.json();

    if (response.success === 1) {
      localStorage.setItem(ISLOGGED, true);
      localStorage.setItem(HACKTEAMTOKEN, response.token);
      localStorage.setItem(SUBMISSION, response.submission);
      localStorage.setItem(PAYLOAD, JSON.stringify(response.payload));
      localStorage.setItem(SUBMISSION, response.submission );
      window.location.reload();
      return;
    }

    alert(response.message);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  }, []);

  return (
    <>
      <div className="header-container absolute z-10 w-full top-0">
        <HackathonHeader />
      </div>

      <div className="stacked-container z-0">
        <Spline
          className={"fixed z-0 scale-[2] translate-x-[45vw]"}
          scene="https://prod.spline.design/9dq72NtO2DuBSfQh/scene.splinecode"
        />

        <div className="main-container xl:w-full s:w-fit s:mx-4">
          <div className="flex flex-col mx-auto items-center justify-center overflow-hidden translate-y-[15vh] lg:w-[52vw] md:w-[75vw] s:h-fit bg-white bg-opacity-40 backdrop-blur-sm rounded drop-shadow-xl border border-slate-200">
            <img
              className="xl:w-[5vw] s:w-[30vw] mt-4"
              src={require("../../../assets/hm.png")}
              alt="GFG Logo"
            />
            <h1 className="block text-black font-bold xl:text-[2.5vw] s:text-[9vw] mb-8">
              Login
            </h1>

            <form onSubmit={handleLogin} className="s:mx-4">
              <div className="w-content rounded-2xl xl:w-[400px] s:w-[80vw] s:mx-0 mx-auto  justify-center items-center py-4">
                <div className="w-full xl:w-[400px] s:w-fit opacity-100 rounded">
                  <label className="xl:text-[1.2vw] s:text-[5vw] font-bold">
                    Team ID
                  </label>
                  <input
                    type="text"
                    name="teamid"
                    onChange={handleChange}
                    placeholder="Team ID"
                    value={loginCred.teamid}
                    className="w-full h-full p-2 mt-2 xl:text-[1.2vw] s:text-[5vw] rounded"
                  />
                </div>
              </div>

              <div className="w-content rounded-2xl xl:w-[400px] s:w-[80vw] s:mx-0 mx-auto  justify-center items-center py-4 ">
                <div className="relative  w-full w-[400px]  opacity-100 rounded">
                  <label className="xl:text-[1.2vw] s:text-[7vw] font-bold">
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    onChange={handleChange}
                    placeholder="Password"
                    value={loginCred.password}
                    className="w-full h-full p-2 mt-2 xl:text-[1.2vw] s:text-[5vw] rounded"
                  />
                </div>
              </div>

              <button
                type="submit"
                className="xl:w-[400px] s:w-[80vw] bg-gfg-green rounded-2xl mt-12 mb-12 mx-auto flex flex-col justify-center items-center py-4 shadow-md cursor-pointer bold font-bold text-white hover:bg-green-700">
                Login
              </button>
            </form>

            <h3 className="text-md w-[80%] mb-6">
              {" "}
              In case of any issues while logging in, contact us using the
              support contact given.{" "}
            </h3>
          </div>
        </div>
      </div>

      <div className="footer-container absolute z-10 w-full translate-y-[20vh]">
        <HackMatrixFooter />
      </div>
    </>
  );
};

export default LoginPage;
